// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import App from "../components/App";
// import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <Router>
      <Route path="/" component={App} />
    </Router>,
    document.body.appendChild(document.createElement("div"))
  );
});

let profileButtonInterval;

profileButtonInterval = setInterval(() => {
  let body = document.querySelector("body");

  if (body) {
    let oneTimeButton = document.querySelector(".one-time-button");
    let modal = document.querySelector(".modal");
    let playButton = document.getElementById("play-button");
    let video = document.querySelector("video");

    if (playButton) {
      playButton.addEventListener("click", () => {
        video.play();
        video.setAttribute("controls", "");
        playButton.style.visibility = "hidden";
      });
    }

    if (oneTimeButton) {
      oneTimeButton.addEventListener("click", () => {
        modal.style.display = "flex";
        gtag("event", "pay_per_listing_click");
      });

      window.onclick = (event) => {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };

      window.onkeydown = (event) => {
        if (event.key === "Escape") {
          modal.style.display = "none";
        }
      };
    }

    let profileButton = document.querySelector(".profile-icon");
    let profileMenu = document.querySelector(".profile-menu");
    if (profileButton) {
      document.addEventListener("click", (evt) => {
        let isClicked = profileButton.contains(evt.target);

        if (isClicked) {
          if (
            profileMenu.style.visibility == "hidden" &&
            profileMenu.style.opacity == "0"
          ) {
            profileMenu.style.visibility = "inherit";
            profileMenu.style.opacity = "1";
          } else if (
            profileMenu.style.visibility == "inherit" &&
            profileMenu.style.opacity == "1"
          ) {
            profileMenu.style.visibility = "hidden";
            profileMenu.style.opacity = "0";
          }
        }

        if (!isClicked) {
          if (
            profileMenu.style.visibility == "inherit" &&
            profileMenu.style.opacity == "1"
          ) {
            profileMenu.style.visibility = "hidden";
            profileMenu.style.opacity = "0";
          }
        }
      });
    }
    clearInterval(profileButtonInterval);
  }
}, 100);
