import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, isSubmitting } from "formik";
import axios from "axios";
import styled from "styled-components";
import "./app.css";
import { ErrorText } from "./GeneratorTwo.js";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Label = styled.label`  
  border: 1.5px solid #E4E9EB;
  text-align: center;
  background: #F3F7F9;
  height: 51px;
  display: inline-block;
  padding 6px;
  font-size: 14px;
  border-radius: 10px 0 0 10px;
  line-height: 200%;
  font-weight: 500;
  min-width: 105px;
  display: flex;
  align-items: center;
  text-indent: 2px;
`;

const SelectInput = styled.select`  
  border: 1.5px solid #E4E9EB;
  border-right: 4px solid #f3f7f9;
  text-align: left;
  background: #F3F7F9;
  height: 51px;
  display: inline-block;
  padding 6px;
  font-size: 14px;
  border-radius: 10px 0 0 10px;
  line-height: 200%;
  font-weight: 500;
  min-width: 105px;
  display: flex;
  align-items: center;
  text-indent: 2px;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right center;
`;

const AttributeInput = styled.input`
  font-size: 14px;
  padding: 6px 8px;
  border: 1.5px solid #e4e9eb;
  background: #f3f7f9;
  width: 100%;
  height: 51px;
  border-radius: 0 10px 10px 0;
`;

const InputDiv = styled.div`
  margin-bottom: 0px !important;
`;

const Container = styled.div`
  display: inline !important;
`;

const BasicHouseAttribute = (props) => {
  const [areaMeasurement, setAreaMeasurement] = useState("sqFeet");

  function handleChange(event) {
    setSizeType(event.target.value);
  }

  const placeholder = data => {
    if (areaMeasurement === "sqFeet") {
      return data["ftPlaceholder"];
    } else if (areaMeasurement === "sqMeters") {
      return data["mPlaceholder"];
    } else if (areaMeasurement === "acres") {
      return data["aPlaceholder"];
    }
  }

  const getDataTypes = () => {
    if (props.propertyType === "Vacant Land") {
      return [
        {
          type: "list",
          name: "Area",
          ftLabel: "Sq Feet",
          mLabel: "Sq Meters",
          aLabel: "Acres",
          ftPlaceholder: "Number of square feet",
          mPlaceholder: "Number of square meters",
          aPlaceholder: "Number of Acres",
          ftName: "sqFeet",
          mName: "sqMeters",
          aName: "acres",
        },
        {
          label: "Zoning",
          placeholder: "residential, commercial, agricultural, etc.",
          name: "zoning",
          type: "text"
        },
      ];
    } else {
      return [
        {
          label: "Bedrooms",
          placeholder: "Number of bedrooms",
          name: "bedrooms",
          type: "single"
        },
        {
          label: "Bathrooms",
          placeholder: "Number of bathrooms",
          name: "bathrooms",
          type: "single"
        },
        {
          type: "list",
          name: "Area",
          ftLabel: "Sq Feet",
          mLabel: "Sq Meters",
          aLabel: "Acres",
          ftPlaceholder: "Number of square feet",
          mPlaceholder: "Number of square meters",
          aPlaceholder: "Number of Acres",
          ftName: "sqFeet",
          mName: "sqMeters",
          aName: "acres",
        },
        {
          label: "Parking",
          placeholder: "Number of parking spots",
          name: "parking",
          type: "single"
        },
      ];
    }
  }

  const renderField = (data) => {
    if (data["type"] === "single") {
      return <InputDiv>
        <Label htmlFor={data["name"]}>{data["label"]}</Label>
        <Field
          as={AttributeInput}
          placeholder={data["placeholder"]}
          name={data["name"]}
          id={data["name"]}
          type="number"
          min="0"
          step="0.5"
          disabled={props.isDisabled}
        />
      </InputDiv>
    } else if (data["type"] === "text") {
      return <InputDiv>
        <Label htmlFor={data["name"]}>{data["label"]}</Label>
        <Field
          as={AttributeInput}
          placeholder={data["placeholder"]}
          name={data["name"]}
          id={data["name"]}
          type="text"
          disabled={props.isDisabled}
        />
      </InputDiv>

    } else {
      return <>
        <InputDiv>
          <Field
            as={SelectInput}
            name="areaMeasurement"
            type="select"
            value={areaMeasurement}
            onChange={(e) => {
              console.log(e.target.value);
              setAreaMeasurement(e.target.value)
            }}
          >
            <option value={data["ftName"]}>{data["ftLabel"]}</option>
            <option value={data["mName"]}>{data["mLabel"]}</option>
            <option value={data["aName"]}>{data["aLabel"]}</option>
          </Field>
          <Field
            as={AttributeInput}
            type="number"
            placeholder={placeholder(data)}
            name="areaSize"
            min="0"
            step="0.5"
            disabled={props.isDisabled}
          />
        </InputDiv>
        <ErrorMessage component={ErrorText} name="areaSize" />
      </>
    }
  }

  return (
    <Container>
      {getDataTypes().map((data, index) => (
        <InputContainer key={index}>
          {renderField(data)}
          <ErrorMessage component={ErrorText} name={data["name"]} />
        </InputContainer>
      ))}
    </Container>
  );
};

export default BasicHouseAttribute;
