export const blogData = [
  {     
    image: "organizedagent.jpg",
    alt: "Desk with organized files and laptop",
    url: "tips-organized-agents",
    title: "Tips For Organizing Your Real Estate Business",      
    subtext: "Curated strategies to organize your real estate business.",
  },
  {     
    image: "citypano.jpg",
    alt: "Sunny City Landscape",
    url: "best-cities-for-agents",
    title: "The Five Best Cities for Real Estate Agents",      
    subtext: "We've uncovered the best cities to be an agent in.",
  },
  {     
    image: "topmistakes.png",
    alt: "Letters forming message about learning from mistakes",
    url: "biggest-mistakes",
    title: "The Five Biggest Mistakes Real Estate Agents Make",      
    subtext: "Avoiding the most common mistakes agents make.",
  },
  {     
    image: "rowhomes.png",
    alt: "Neighborhood with similar looking homes",
    url: "listing-stand-out",
    title: "Making Your Home Listing Stand Out in 2023",      
    subtext: "We've curated the top strategies for making your real estate listings stand out!",
  },
  {     
    image: "aiblogpost.png",
    alt: "Robot pointing on a wall",
    url: "ai-real-estate",
    title: "Artificial Intelligence and Real Estate",      
    subtext: "How could AI be used in Real Estate? We'll share some examples!",
  },
  {     
    image: "realEstateWordsHeader.jpg",
    alt: "Real estate words written out using scrabble pieces",
    url: "best-real-estate-words-and-synonyms",
    title: "Best Real Estate Words and Synonyms That Sell",      
    subtext: "We've compiled the top real estate words and synonyms that sell – use them to improve your listing descriptions today!",
  },
  {     
    image: "whyUseGeneratorImage.png",
    alt: "Person Holding Gray Pen Sitting Near Laptop",
    url: "5-reasons-to-use-a-real-estate-description-generator",
    title: "5 Reasons to Use a Real Estate Description Generator",      
    subtext: "We’ll dive into the 5 reasons you should be using a real estate listing description generator, and why it's the most important realtor tool 2023",
  },
  {     
    image: "descriptionExamplesHeader.png",
    alt: "Real estate agent working at a laptop computer writing real estate descriptions",
    url: "real-estate-description-examples",
    title: "Real Estate Description Examples - Best of 2023",      
    subtext: "We've scoured the internet to bring you the most creative real estate listing description examples.",
  },
  {     
    image: "howToWriteHeader.png",
    alt: "Man writing a real estate listing description with a pen on pads of paper",
    url: "how-to-write-a-real-estate-listing-description",
    title: "How To Write a Real Estate Listing Description",      
    subtext: "Writing a property description is an art – we'll show you 7-tips to write the best ones!",
  },
  {     
    image: "bestRealtorToolsImage.png",
    alt: "Two men stare at a laptop together on a couch",
    url: "7-best-tools-for-realtors",
    title: "7 Best Tools for Tech Focused Realtors in 2023",      
    subtext: "Once you start using these tools, you’ll wonder how you ever lived without them!",
  },
];



