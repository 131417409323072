import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ErrorText } from "./GeneratorTwo.js";
import styled from "styled-components";
import "./app.css";

const Container = styled.div`
  width: 100%;
  min-width: 320px;
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  margin-top: 1em;
`;

const Title = styled.p`
  font-size: 16px;
  color: #032231;
  font-weight: 700;
  margin: 0 0 1em 0;
  font-family: "Inter", sans-serif;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  width: 100%;
  flex-wrap: wrap;

  & > * {
    flex: 1 1 calc(50% - 40px);
    margin-bottom: 10px;
    margin-top: 6px;
    &:nth-child(odd) {
      margin-right: 20px; 
    }
  }
  
`;

const MiniTitle = styled.p`
  :last-child {
    margin-bottom: 0px;
  }

  margin-bottom: ${(props) => (props.type == "propertyType" ? "1em" : "0px")};
`;

const SubDescription = styled.p`
  font-size: 12px;
  margin-top: -12px;
`

const ButtonSelectorContainer = (props) => {
  const dataTypes = {
    propertyType: ["House", "Multi-Family", "Condo", "Townhouse", "Vacant Land"],
    transactionType: ["For sale", "For rent"],
    tone: ["Formal", "In-Between", "Excitable"],
    creativityMode: ["Neutral", "Creative"]
  };

  return (
    <Container>
      <Title>{props.title}</Title>
      {props.description && <SubDescription>{props.description}</SubDescription> }
      <ButtonContainer direction={props.direction}>
        {dataTypes[props.type].map((title, index) => (
          <MiniTitle type={`${props.type}`} key={index}>            
            <Field class = "radio-button"
              type="radio"
              name={`${props.type}`}
              value={title}
              disabled={props.isDisabled}
              onClick={props.onChange}
            />
            {title}
          </MiniTitle>
        ))}
      </ButtonContainer>
      <ErrorMessage component={ErrorText} name={`${props.type}`} />
    </Container>
  );
};

ButtonSelectorContainer.defaultProps = {
  direction: "row",
};

export default ButtonSelectorContainer;
