import React from "react";
import styled from "styled-components";
import "./app.css";

const StarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Star = styled.div`
  margin-left: 12px;
  cursor: pointer;
  font-size: 50px;
`;

const StarSelector = (props) => {
  const dataTypes = {
    rating: ["1", "2", "3", "4", "5"],
  };

  function setStar(e) {
    e.preventDefault();
    props.setStar(e.target.id);
    props.nextStep();
  }

  function handleHover(e) {
    e.target.style.color = "#007BFF";
    const hoverStar = e.target.id;
    const allStars = document.querySelectorAll(".star");

    allStars.forEach((star) => {
      if (star.id <= hoverStar) {
        star.style.color = "#007BFF";
      }
    });
  }

  function handleOut(e) {
    const allStars = document.querySelectorAll(".star");
    allStars.forEach((star) => {
      star.style.color = "#032231";
    });
  }

  return (
    <div>
      <StarContainer id="starContainer">
        <p>
          Help us improve this tool by rating the quality of the listing
          descriptions you just generated
        </p>
        <StarWrapper>
          {dataTypes["rating"].map((data, index) => (
            <div key={index}>
              <Star
                className="star"
                id={data}
                onMouseOver={handleHover}
                onMouseOut={handleOut}
                onClick={setStar}
              >
                ★
              </Star>
            </div>
          ))}
        </StarWrapper>
      </StarContainer>
    </div>
  );
};

export default StarSelector;