import React from "react";
import styled from "styled-components";
import {
  Section,
  OutlineButton,
  CtaButton,
  HeaderContainer,
  ButtonContainer,
  HeaderText,
  SubHeading,
} from "./style.js";

function handleClick() {
  // gtag("event", "lander_header_cta_click");
  window.location.href = "/users/sign_up";
}

const Header = (props) => {
  return (
    <Section>
      <HeaderContainer>
        <HeaderText>
          Write custom real estate listing descriptions in seconds
        </HeaderText>
        <SubHeading>
          Save time and sell more with AI generated real estate listing
          descriptions with a click of a button.
        </SubHeading>
        <ButtonContainer>
          <CtaButton onClick={handleClick}>Start writing</CtaButton>
        </ButtonContainer>
      </HeaderContainer>
    </Section>
  );
};

export default Header;
