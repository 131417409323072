import React from "react"
import { Route, Switch } from "react-router-dom"
import Generator from "./listings/Generator"
import GeneratorTwo from "./GeneratorTwo"
import GeneratorNew from "./GeneratorNew"
import GenerateListing from "./GenerateListing";
import Dashboard from "./Dashboard"
import LandingPage from "./landingPage/LandingPage"
import PrivacyPolicy from "./landingPage/PrivacyPolicy"
import Faq from "./landingPage/Faq"
import TermsOfUse from "./landingPage/TermsOfUse"
import Blog from "./landingPage/blogPage/Blog"
import BestTools from "./landingPage/blogPage/BestTools"
import HowToWrite from "./landingPage/blogPage/HowToWrite"
import RealEstateAI from "./landingPage/blogPage/RealEstateAI"
import ListingStandOut from "./landingPage/blogPage/ListingStandOut"
import BiggestMistakes from "./landingPage/blogPage/BiggestMistakes"
import DescriptionExamples from "./landingPage/blogPage/DescriptionExamples"
import WhyUseGenerator from "./landingPage/blogPage/WhyUseGenerator"
import BestRealEstateWords from "./landingPage/blogPage/BestRealEstateWords"
import BestCities from "./landingPage/blogPage/BestCities"
import OrganizedAgent from "./landingPage/blogPage/OrganizedAgent"
import ScrollToTop from "./ScrollToTop"

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route exact path="/old" component={Generator} />
        <Route exact path="/generate_old" component={GeneratorTwo} />
        <Route exact path="/generate" component={GeneratorNew} />
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/terms" component={TermsOfUse} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/generate_listing/:id/:address" component={GenerateListing} />
        <Route
          exact
          path="/blog/best-real-estate-words-and-synonyms"
          component={BestRealEstateWords}
        />
        <Route
          exact
          path="/blog/7-best-tools-for-realtors"
          component={BestTools}
        />
        <Route
          exact
          path="/blog/how-to-write-a-real-estate-listing-description"
          component={HowToWrite}
        />
        <Route
          exact
          path="/blog/real-estate-description-examples"
          component={DescriptionExamples}
        />
        <Route
          exact
          path="/blog/5-reasons-to-use-a-real-estate-description-generator"
          component={WhyUseGenerator}
        />
        <Route
          exact
          path="/blog/ai-real-estate"
          component={RealEstateAI}
        />
        <Route
          exact
          path="/blog/biggest-mistakes"
          component={BiggestMistakes}
        />
        <Route
          exact
          path="/blog/listing-stand-out"
          component={ListingStandOut}
        />
       <Route
          exact
          path="/blog/best-cities-for-agents"
          component={BestCities}
        />
      <Route
          exact
          path="/blog/tips-organized-agents"
          component={OrganizedAgent}
        />
      </Switch>
    </>
  );
};

export default App;
