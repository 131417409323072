import React, {useEffect, useState} from "react";
import {Formik, Field, Form, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import styled from "styled-components";
import "./app.css";
import BasicHouseAttribute from "./BasicHouseAttributes.js";
import ButtonSelectorContainer from "./ButtonSelectorContainer.js";
import LangSelectorContainer from "./LangSelectorContainer.js";
import OutputSelector from "./OutputSelector.js";
import DescriptionOutput from "./DescriptionOutput.js";
import Footer from "./landingPage/Footer.js";
import LoadingSpinner from "./LoadingSpinner.js";
import PropertyDetailsContainer from "./PropertyDetailsContainer.js";

import {AddressAutofill, config} from '@mapbox/search-js-react';
import {v4 as uuidv4} from 'uuid';
import {Link, useParams} from "react-router-dom";

const MainContainer = styled.div`
  display: block;
  padding-bottom: 2em;
  max-width: 48em;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 780px) {
    padding: 0 1em 0 1em;
  }
`;

const GenerateButtonContainer = styled.div`
  text-align: center;
`;

const LoadingText = styled.div`
  font-style: italic;
  margin-top: 10px;
  min-height: 24px;
  margin-top: 1.5em;
`;

export const ErrorText = styled.p`
  color: red;
  margin: 12px 0px 0px 0px;
  font-size: 12px;
`;

const GenerateButton = styled.button`
  background: #032231;
  color: #fff;
  border-radius: 20px;
  margin: 0 auto;
  width: 320px;
  min-height: 64px;
  justify-content: center;
  padding: 20px;
  display: flex;
  border: none;
  cursor: pointer;
  font-weight: 700;

  &:hover {
    opacity: 0.8;
  }

  outline: none;

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 634px) {
    width: 100%;
  }
`;

const NextButton = styled.button`
  background: #032231;
  color: #fff;
  border-radius: 20px;
  margin: 0 auto;
  width: 320px;
  min-height: 64px;
  justify-content: center;
  padding: 20px;
  display: flex;
  border: none;
  cursor: pointer;
  font-weight: 700;

  &:hover {
    opacity: 0.8;
  }

  outline: none;

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 634px) {
    width: 100%;
  }
`;

const AttributeContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  padding-bottom: 2em;

  & > * + * {
    margin: 0 0 0 2em;
  }

  @media screen and (max-width: 704px) {
    flex-direction: column;

    & > * + * {
      margin: 0 0 0 0;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: center;
  margin: 0 10px;
  &:nth-child(1) {
    align-items: flex-end;
  }

  &:nth-child(2) {
    align-items: flex-start;
  }

  @media screen and (max-width: 850px) {
    &:nth-child(1) {
      align-items: center;
    }

    &:nth-child(2) {
      align-items: center;
    }
  }
`;

export const Container = styled.div`
  min-width: 320px;
  width: 100%;
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  margin-top: 1em;

  & div {
    display: flex;
  }

  & div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Title = styled.p`
  font-size: 16px;
  color: #032231;
  font-weight: 700;
  margin: 0 0 1em 0;
  font-family: "Inter", sans-serif;
`;

const InputField = styled.input`
  font-size: 14px;
  padding: 6px 8px;
  border: 1.5px solid #e4e9eb;
  background: #f3f7f9;
  width: 100%;
  height: 51px;
  border-radius: 10px;
`;

const InputDiv = styled.div`
  margin-bottom: 0px !important;
`;

const ProgressIndicator = ({ currentStep, totalSteps }) => {
    const progress = (currentStep / totalSteps) * 100;

    return (
        <div style={{ width: '100%', backgroundColor: '#C9C9C9', borderRadius: '5px', margin: '10px 0', position: 'relative' }}>
            <div style={{ height: '10px', width: `${progress}%`, backgroundColor: 'blue', borderRadius: '5px' }}></div>
        </div>
    );
};


const StepContainer = ({ children }) => (
    <div style={{ marginTop: '20px', width: '100%' }}>
        {children}
    </div>
);

const generalSchema = {
    characterCount: Yup.number()
        .positive()
        .required("Description Length Required")
        .min(100, "Generated description can't be shorter than 100 words")
        .max(500, "Generated description can't be longer than 500 words"),
    bedrooms: Yup.number().min(0).required("Number of Bedrooms Required"),
    propertyAddress: Yup.string().required("Address of Property Required"),
    bathrooms: Yup.number().positive().required("Number of Bathrooms Required"),
    areaSize: Yup.number().positive().required("Size of Property Required"),
    parking: Yup.number().min(0),
    transactionType: Yup.string().required("Transaction Type Required"),
    creativityMode: Yup.string().required("AI Writing Creativity Level Required"),
    propertyType: Yup.string().required("Property Type Required"),
    propertyDetails: Yup.string().max(
        1000,
        "Kitchen details must be shorter than 1000 characters"
    ),
}

const vacantLandSchema = {
    characterCount: Yup.number()
        .positive()
        .required("Description Length Required")
        .min(100, "Generated description can't be shorter than 100 words")
        .max(500, "Generated description can't be longer than 500 words"),
    propertyAddress: Yup.string().required("Address of Property Required"),
    areaSize: Yup.number().positive().required("Size of Property Required"),
    zoning: Yup.string().required("Zoning Information Required"),
    transactionType: Yup.string().required("Transaction Type Required"),
    creativityMode: Yup.string().required("AI Writing Creativity Level Required"),
    propertyType: Yup.string().required("Property Type Required"),
    propertyDetails: Yup.string().max(
        1000,
        "Kitchen details must be shorter than 1000 characters"
    ),
}

const GenerateListing = () => {
    const {id, address} = useParams()
    const [listingId, setListingId] = useState(0)
    const [descriptions, setDescriptions] = useState();
    const [socials, setSocials] = useState();
    const [outputToggle, setOutputToggle] = useState("description");
    const [generationLimit, setGenerationLimit] = useState();
    const [activeSub, setActiveSub] = useState();
    const [unlimited, setUnlimited] = useState();
    const [showRating, setShowRating] = useState(true);
    const [showShortenText, setShowShortenText] = useState(true);
    const [mapboxToken, setMapboxToken] = useState('');
    const [propertyType, setPropertyType] = useState("House");
    const schema = propertyType === "Vacant Land" ? vacantLandSchema : generalSchema
    const GenerationSchema = Yup.object().shape(schema);
    const [previousDescriptions, setPreviousDescriptions] = useState([]);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [completeAddress, setCompleteAddress] = useState(address);
    const sectionRefs = ["section1", "section2", "section3"];
    const [updateTextButtons, setUpdateTextButtons] = useState(false);
    const [listingData, setListingData] = useState({
        id: '',
        property_type: '',
        transaction_type: '',
        length: '',
        bedrooms: '',
        bathrooms: '',
        area: '',
        parking: '',
        user_id: '',
        created_at: '',
        updated_at: '',
        address: '',
        general_details: '',
        kitchen_details: '',
        bathroom_details: '',
        room_details: '',
        outdoor_details: '',
        misc_details: '',
        area_unit: '',
        unique_address: '',
        zoning: ''
    });

    // State to manage visibility of form sections
    const totalSteps = 3; // Assuming there are 3 steps in your form
    const [currentStep, setCurrentStep] = useState(1);

    // Function to handle showing the second part
    const handleNextClick = () => {
        if (currentStep < totalSteps) {
            setCurrentStep(currentStep+1)
        }
        let nextSection;
        if (currentStep < totalSteps) {
            nextSection = sectionRefs[currentStep];
        } else if (currentStep === totalSteps) {
            nextSection = "finalOutput";
        }
        if (nextSection) {
            setTimeout(() => {
                const nextSectionElement = document.getElementById(nextSection);
                if (nextSectionElement) {
                    nextSectionElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
        }
    };

    useEffect(() => {
        setCompleteAddress(address)
    }, []);

    useEffect(getListingWithId, []);

    useEffect(getDescriptions, []);

    useEffect(getGenerationLimit, []);

    useEffect(listenAddressUpdate, []);

    useEffect(() => {
        const accessToken = "pk.eyJ1IjoiY290dGFnZW1hcHMiLCJhIjoiY2x0ajZtM3FzMG5oMzJpcnJsY2swNmo5bCJ9.kIcKN5vmKR8uI3eH9RyVcg";
        setMapboxToken(accessToken);
        config.accessToken = accessToken;
        document.getElementById("propertyAddress").name = "propertyAddress";
    }, []);

    async function handlePrompt() {
        setShowRating(false);
    }

    async function getListingWithId() {
        axios.defaults.headers.common["X-CSRF-TOKEN"] = document.querySelector('meta[name="csrf-token"]').content;
        await axios({
            method: "get",
            url: "/listing/" + id,
        })
            .then((response) => {
                if (response.data != null) {
                    setListingData(response.data)
                    if (response.data.property_type === "Vacant Land") {
                        setPropertyType("Vacant Land");
                    } else {
                        setPropertyType("House");
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response && error.response.status === 401) {
                    // Unauthorized, redirect to the login page
                    window.location.href = "/users/sign_in";
                }
                if (error.response.status) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
    }

    async function getDescriptions() {
        axios.defaults.headers.common["X-CSRF-TOKEN"] = document.querySelector('meta[name="csrf-token"]').content;
        await axios({
            method: "get",
            url: "/descriptions/" + completeAddress,

        })
            .then((response) => {
                if (response.data != null) {
                    setPreviousDescriptions(response.data.descriptions)
                    setListingId(response.data.listing_id[0])
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response && error.response.status === 401) {
                    // Unauthorized, redirect to the login page
                    window.location.href = "/users/sign_in";
                }
                if (error.response.status) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
    }

    async function getGenerationLimit() {
        let token = document.querySelector('meta[name="csrf-token"]').content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
        axios({
            method: "get",
            url: "/api/v1/generation_limits",
        })
            .then((resp) => {
                setGenerationLimit(resp["data"].generations);
                setActiveSub(resp["data"].activeSub);
                setUnlimited(resp["data"].unlimited);
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
    }

    async function socialGenerated(results) {
        setSocials(results);
        setOutputToggle("social");
    }

    function extractAndRemoveUnit(address) {
        const unitRegex = /\(unit: (.+?)\)/;
        let decodedAddress = decodeURIComponent(address);
        const match = decodedAddress.match(unitRegex);

        let unit = "";
        let cleanedAddress = decodedAddress;

        if (match && match[1]) {
            unit = match[1].trim();
            cleanedAddress = decodedAddress.replace(unitRegex, '').trim(); // Remove the unit part
        }
        return { unit, cleanedAddress };
    }

    const { unit, cleanedAddress } = extractAndRemoveUnit(listingData.address);

    function listenAddressUpdate() {
        const addressInput = document.getElementById("propertyAddress");
        const unitInput = document.getElementById("unitNumber");
        addressInput.addEventListener("change", updateListingData);
        unitInput.addEventListener("change", updateListingData);
    }

    function updateListingData() {
        const newAddress = document.getElementById("propertyAddress").value;
        const newUnitNumber = document.getElementById("unitNumber").value;
        let newCompleteAddress = newAddress.trim();
        if (newUnitNumber.trim() !== "") {
            newCompleteAddress += " (unit: " + newUnitNumber + ")";
        }
        getDescriptionsForNewAddress(newCompleteAddress)
    }

    function getDescriptionsForNewAddress(newAddress) {
        setPreviousDescriptions([])
        let encodeAddress = encodeURIComponent(newAddress)
        setCompleteAddress(encodeAddress);
        setUpdateTextButtons(false);
        axios.defaults.headers.common["X-CSRF-TOKEN"] = document.querySelector('meta[name="csrf-token"]').content;
        axios({
            method: "get",
            url: "/descriptions/" + encodeAddress,

        })
            .then((response) => {
                if (response.data != null) {
                    setPreviousDescriptions(response.data.descriptions)
                    setListingId(response.data.listing_id[0])
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
    }

    function copyToClipboard(text) {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }



    return (
        <p className="prev-desc-title">listingData.address</p>,
            <Formik
                initialValues={{
                    propertyAddress: cleanedAddress,
                    unitNumber: unit,
                    bedrooms: listingData.bedrooms,
                    bathrooms: listingData.bathrooms,
                    areaMeasurement: listingData.area_unit,
                    areaSize: listingData.area,
                    parking: listingData.parking,
                    zoning: listingData.zoning,
                    transactionType: listingData.transaction_type,
                    propertyType: listingData.property_type,
                    language: "english us",
                    characterCount: "250",
                    creativityMode: "Neutral",
                    propertyDetails: "",
                }}
                enableReinitialize
                validationSchema={GenerationSchema}
                onSubmit={async (data) => {
                    //show the fields
                    var descriptions = [{id: 1, result: ""}]
                    setDescriptions(descriptions);

                    const channelName = uuidv4();

                    const pusher = new Pusher('30da35e03bd157814eff', {
                        cluster: 'us2'
                    });
                    const channel = pusher.subscribe(channelName);

                    channel.bind('description-generation', function (data) {
                        const content = data.message.choices[0].delta.content;
                        if (content === undefined) {
                            pusher.disconnect();
                            setShowRating(true);
                        } else {
                            setDescriptions(prevDescriptions => {
                                return [
                                    {
                                        ...prevDescriptions[0],
                                        ["result"]: prevDescriptions[0].result + content,
                                    }
                                ]
                            });
                        }
                    });

                    setOutputToggle("description");

                    let address = data["propertyAddress"].trim();
                    if (data["unitNumber"].trim() !== "") {
                        address += " (unit: " + data["unitNumber"] + ")";
                    }

                    const params = new URLSearchParams();

                    params.append(
                        "transactionType",
                        JSON.stringify(data["transactionType"])
                    );
                    params.append("propertyType", JSON.stringify(data["propertyType"]));
                    params.append(
                        "propertyAddress",
                        JSON.stringify(address)
                    );

                    params.append("language", JSON.stringify(data["language"]));

                    params.append("bedrooms", JSON.stringify(data["bedrooms"]));
                    params.append("bathrooms", JSON.stringify(data["bathrooms"]));
                    params.append("areaSize", JSON.stringify(data["areaSize"]));
                    params.append("areaMeasurement", JSON.stringify(data["areaMeasurement"]));

                    params.append("parking", JSON.stringify(data["parking"]));
                    params.append("zoning", JSON.stringify(data["zoning"]));
                    params.append("wordCount", JSON.stringify(data["characterCount"]));
                    params.append("propertyDetails", JSON.stringify(data["propertyDetails"]));
                    params.append("creativityMode", JSON.stringify(data["creativityMode"]));

                    params.append("channel", JSON.stringify(channelName));


                    let token = document.querySelector('meta[name="csrf-token"]').content;
                    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

                    await axios({
                        method: "post",
                        url: "/api/v1/descriptions",
                        data: params,
                    })
                        .then((resp) => {
                            console.log("params", params);
                            console.log("response", resp["data"]);
                            getGenerationLimit();
                            setListingId(resp["data"][0]["description"]["listing_id"])
                            setUpdateTextButtons(true);
                            setDescriptions(prevDescriptions => {
                                return [
                                    {
                                        ...prevDescriptions[0],
                                        ["id"]: resp["data"][0]["description"]["id"],
                                    }
                                ]
                            });
                            gtag("event", "description_generated_success");
                        })
                        .catch(function (error) {
                            console.log(error.response.status)
                            if (error.response.status === 402) {
                                console.log(error.response.data)
                                console.log(error.response.status)
                                console.log(error.response.headers)
                                gtag("event", "description_generated_failure_plan_expired")
                                window.location.href = activeSub ? "/pricing?outofsubgens" : "/pricing?outofgens"
                            }
                            gtag("event", "description_generated_failure")
                        })
                    await getDescriptions();
                }}
            >
                {({isSubmitting, setTouched}) => (
                    <Form>
                        <MainContainer>
                            <Link to="/dashboard" className="back-button">&larr; Back</Link>
                            <ProgressIndicator currentStep={currentStep} totalSteps={totalSteps} />
                            <AttributeContainer>
                                {currentStep >0 && (
                                    <>
                                    <Column>
                                        <Container>
                                            <Title>Property Address</Title>
                                            <AddressAutofill accessToken={mapboxToken}>
                                                <InputDiv>
                                                    <Field
                                                        autoComplete="full-address"
                                                        as={InputField}
                                                        placeholder="Property address"
                                                        name="propertyAddress"
                                                        id="propertyAddress"
                                                        type="string"
                                                        disabled={isSubmitting}
                                                    />
                                                </InputDiv>
                                            </AddressAutofill>
                                            <ErrorMessage component={ErrorText} name="propertyAddress"/>

                                            <InputDiv>
                                                <Field
                                                    as={InputField}
                                                    type="string"
                                                    placeholder="Unit # (optional)"
                                                    name="unitNumber"
                                                    id="unitNumber"
                                                    style={{marginTop: 20}}
                                                    disabled={isSubmitting}
                                                />
                                            </InputDiv>
                                        </Container>
                                    </Column>
                                    <Column>
                                        <ButtonSelectorContainer
                                            direction="row"
                                            title="Property type"
                                            type="propertyType"
                                            isDisabled={isSubmitting}
                                            value={propertyType}
                                            onChange={(e) => {
                                                setPropertyType(e.target.value);
                                                setTouched({}, false);
                                            }}
                                        />
                                    </Column>
                                    </>
                                )}
                                {currentStep >1 && (
                                    <>
                                        <Column>
                                            <Container id="section2">
                                                <Title>Basic attributes</Title>
                                                <BasicHouseAttribute isDisabled={isSubmitting} propertyType={propertyType}/>
                                            </Container>
                                                <ButtonSelectorContainer
                                                direction="row"
                                                title="Transaction type"
                                                type="transactionType"
                                                isDisabled={isSubmitting}
                                            />
                                        </Column>
                                        <Column>
                                            <PropertyDetailsContainer isDisabled={isSubmitting} propertyType={propertyType}/>
                                        </Column>
                                    </>
                                )}
                                {currentStep >2 && (
                                    <>
                                        <Column id="section3"><LangSelectorContainer
                                            title="Language"
                                            type="language"
                                            isDisabled={isSubmitting}
                                        /></Column>
                                        <Column>
                                            <Container>
                                            <Title>Description length (words)</Title>
                                            <InputDiv>
                                                <Field
                                                    as={InputField}
                                                    type="number"
                                                    min="0"
                                                    placeholder="Number of words (minimum 100 words)"
                                                    name="characterCount"
                                                    disabled={isSubmitting}
                                                />
                                            </InputDiv>
                                            <ErrorMessage component={ErrorText} name="characterCount"/>
                                            </Container>
                                        </Column>
                                        <Column>
                                            <ButtonSelectorContainer
                                                title="AI Writing Creativity"
                                                type="creativityMode"
                                                description="Adjust how creative the AI should write. Neutral is recommended for increased accuracy."
                                                hasDescription
                                                isDisabled={isSubmitting}
                                            />
                                        </Column>
                                    </>
                                )}
                            </AttributeContainer>
                            {currentStep < totalSteps && (
                                <GenerateButtonContainer>
                                    <NextButton className=".Gen" id="nextButton" type="button" onClick={handleNextClick}>Next</NextButton>
                                </GenerateButtonContainer>
                            )}

                            {currentStep >= totalSteps &&  (
                            <GenerateButtonContainer>
                                <GenerateButton id="generateButton" type="submit" onClick={handleNextClick}>
                                    {!isSubmitting && "Generate description"}
                                    {isSubmitting && <LoadingSpinner color="#fff"/>}
                                </GenerateButton>
                                <LoadingText
                                    id="finalOutput">
                                    {isSubmitting && "This could take a minute"}

                                    {!isSubmitting && !unlimited ? (
                                        <p>
                                            You have {generationLimit} generation(s) remaining.{" "}
                                            {!activeSub &&
                                                <a href="/pricing">See pricing plans</a>
                                            }
                                            {(activeSub && generationLimit === 0) &&
                                                <a href="/pricing">Upgrade your plan</a>
                                            }
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                </LoadingText>
                            </GenerateButtonContainer>
                                )}
                            <OutputSelector
                                descriptions={descriptions}
                                selection={outputToggle}
                                onChangeSelection={setOutputToggle}
                            />
                            {outputToggle === "description" && (
                                <DescriptionOutput
                                    showRatingPrompt={showRating}
                                    showUpdateTextButtons={updateTextButtons}
                                    handlePrompt={handlePrompt}
                                    descriptions={descriptions}
                                    type="description"
                                    socialGenerated={socialGenerated}
                                    blur="true"
                                    defaultValue=""
                                    activeSub={activeSub}
                                    listingId={listingId}
                                    updatePreviousDescriptions={getDescriptions}
                                />
                            )}
                            {outputToggle === "social" && (
                                <DescriptionOutput
                                    descriptions={socials}
                                    showShortenTextButton={false}
                                    type="social"
                                    regenerate="false"
                                    blur="true"
                                    defaultValue=""
                                    activeSub={activeSub}
                                />
                            )}

                            <div className="col-lg-14">
                                {previousDescriptions.map((description) => (
                                    <div className="prev-desc-content" key={description.id}>
                                        <textarea className="desc-textarea" defaultValue={description.result}></textarea>
                                        <div className={`copy-button ${buttonClicked[description.id] ? 'clicked' : ''}`} onClick={() => {
                                            copyToClipboard(description.result);
                                            const updatedButtonClicked = { ...buttonClicked, [description.id]: true };
                                            setButtonClicked(updatedButtonClicked);
                                            setTimeout(() => {
                                                const resetButtonClicked = { ...updatedButtonClicked, [description.id]: false };
                                                setButtonClicked(resetButtonClicked);
                                            }, 300);
                                        }}>
                                            Copy
                                        </div>
                                    </div>
                                ))}
                            </div>


                            <Footer/>
                        </MainContainer>
                    </Form>
                )}
            </Formik>
    );
};

export default GenerateListing;
