import React from "react";
import styled from "styled-components";
import {
  Section,
  MidHeading,
  ValuePropContainer,
  ValuePropItem,
  Title,
  SubText,
} from "./style.js";

const ValueProps = (props) => {
  const dataTypes = [
    {     
      title: "Save Time",
      subtext: "Agents spend 30mins-1hr on average writing a single property description – ListingAI takes just 5 minutes",
    },
    {     
      title: "Save Money",
      subtext: "Agents & brokerages will spend between $50-$200 hiring freelance copywriters to write a single description.",
    },
    {      
      title: "Improve Readability",
      subtext: "ListingAI descriptions reach high 60+ Flesch scores for readability and grammatical accuracy.",
    },
    {      
      title: "Boost SEO",
      subtext: "The majority of house searches start on Google – Unique AI generated descriptions help to boost your Google search ranking.",
    },
  ];

  return (
    <Section style={{marginTop: "6em"}}>
      <MidHeading>Why use ListingAI</MidHeading>      
        <ValuePropContainer>
        {dataTypes.map((data, index) => (
          <ValuePropItem key={index}>                        
            <Title>{data["title"]}</Title>
            <SubText>{data["subtext"]}</SubText>
          </ValuePropItem>
        ))}
        </ValuePropContainer>      
    </Section>
  );
};

export default ValueProps;
