import React from "react";
import styled from "styled-components";
import {
  Section,
  BlogContentContainer,
  Title,
  SubText,
  BlogContent,
} from "../style.js";
import { Link } from "react-router-dom";
import { blogData } from "./BlogData"

const BlogBody = () => {
  return (
    <Section>
      <BlogContentContainer>
        {blogData.map((data, index) =>  (
          <BlogContent key={index}>
            <Link to={"/blog/" + data.url}><img src={require('images/' + data.image)} alt={data.alt} /></Link>
            <Link to={"/blog/" + data.url}><Title>{data.title}</Title></Link>
            <SubText>{data.subtext}</SubText>
          </BlogContent>      
        ))}
      </BlogContentContainer>      
    </Section>
  );
};

export default BlogBody;
