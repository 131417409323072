import React, { useRef } from "react";
import Footer from "../Footer";
import BlogHeader from "./BlogHeader";
import BlogBody from "./BlogBody";
import styled from "styled-components";


const Blog = () => {

const LandingContainer = styled.div`
  @media screen and (max-width: 584px) {
    padding: 0 1em 0 1em;
  }
`;

  return (
    <LandingContainer>
      <BlogHeader />
      <BlogBody />
      <Footer />
    </LandingContainer>
  );
};

export default Blog;
