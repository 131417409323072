import React, { useEffect, useState } from "react";
import { Formik, Field, Form, isSubmitting, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import styled from "styled-components";
import "./app.css";
import BasicHouseAttribute from "./BasicHouseAttributes.js";
import ButtonSelectorContainer from "./ButtonSelectorContainer.js";
import OutputSelector from "./OutputSelector.js";
import DescriptionOutput from "./DescriptionOutput.js";
import Footer from "./landingPage/Footer.js";
import LoadingSpinner from "./LoadingSpinner.js";
import FreeFlowContainers from "./FreeFlowContainers.js";
import RatingPrompt from "./RatingPrompt.js";

const MainContainer = styled.div`
  display: block;
  padding-bottom: 2em;
  max-width: 48em;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 780px) {
    padding: 0 1em 0 1em;
  }
`;

const GenerateButtonContainer = styled.div`
  text-align: center;
`;

const LoadingText = styled.div`
  font-style: italic;
  margin-top: 10px;
  min-height: 24px;
  margin-top: 1.5em;
`;

export const ErrorText = styled.p`
  color: red;
  margin: 12px 0px 0px 0px;
  font-size: 12px;
`;

const GenerateButton = styled.button`
  background: #032231;
  color: #fff;
  border-radius: 20px;
  margin: 0 auto;
  width: 320px;
  min-height: 64px;
  justify-content: center;
  padding: 20px;
  display: flex;
  border: none;
  cursor: pointer;
  font-weight: 700;
  &:hover {
    opacity: 0.8;
  }
  outline: none;

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 634px) {
    width: 100%;
  }
`;

const AttributeContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  padding-bottom: 2em;

  & > * + * {
    margin: 0 0 0 2em;
  }

  @media screen and (max-width: 704px) {
    flex-direction: column;

    & > * + * {
      margin: 0 0 0 0;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: center;

  &:nth-child(1) {
    align-items: flex-end;
  }
  &:nth-child(2) {
    align-items: flex-start;
  }

  @media screen and (max-width: 850px) {
    &:nth-child(1) {
      align-items: center;
    }
    &:nth-child(2) {
      align-items: center;
    }
  }
`;

export const Container = styled.div`
  min-width: 320px;
  width: 100%;
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  margin-top: 1em;

  & div {
    display: flex;
  }
  & div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Title = styled.p`
  font-size: 16px;
  color: #032231;
  font-weight: 700;
  margin: 0 0 1em 0;
  font-family: "Inter", sans-serif;
`;

const InputField = styled.input`
  font-size: 14px;
  padding: 6px 8px;
  border: 1.5px solid #e4e9eb;
  background: #f3f7f9;
  width: 100%;
  height: 51px;
  border-radius: 10px;
`;

const InputDiv = styled.div`
  margin-bottom: 0px !important;
`;

const TextArea = styled.textarea`
  font-size: 14px;
  padding: 6px 8px;
  border: 1.5px solid #e4e9eb;
  background: #f3f7f9;
  width: 100%;
  height: 184px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  resize: vertical;
`;

const GenerationSchema = Yup.object().shape({
  characterCount: Yup.number()
    .positive()
    .required("Description Length Required")
    .min(100, "Generated description can't be shorter than 100 words")
    .max(500, "Generated description can't be longer than 500 words"),
  bedrooms: Yup.number().min(0).required("Number of Bedrooms Required"),
  propertyAddress: Yup.string().required("Address of Property Required"),
  bathrooms: Yup.number().positive().required("Number of Bathrooms Required"),
  areaSize: Yup.number().positive().required("Size of Property Required"),
  parking: Yup.number().min(0),
  transactionType: Yup.string().required("Transaction Type Required"),
  creativityMode: Yup.string().required("AI Writing Creativity Level Required"),
  propertyType: Yup.string().required("Property Type Required"),
  generalDetails: Yup.string().max(
    200,
    "General details must be less shorter than 200 characters"
  ),
  kitchenDetails: Yup.string().max(
    200,
    "Kitchen details must be less shorter than 200 characters"
  ),
  bathroomDetails: Yup.string().max(
    200,
    "Bathroom details must be less shorter than 200 characters"
  ),
  roomDetails: Yup.string().max(
    200,
    "Room details must be less shorter than 200 characters"
  ),
  outdoorDetails: Yup.string().max(
    200,
    "Outdoor details must be less shorter than 200 characters"
  ),
  miscellaneousDetails: Yup.string().max(
    200,
    "Miscellaneous details must be less shorter than 200 characters"
  ),
});

const GeneratorTwo = () => {
  const [descriptions, setDescriptions] = useState();
  const [socials, setSocials] = useState();
  const [outputToggle, setOutputToggle] = useState("description");
  const [generationLimit, setGenerationLimit] = useState();
  const [activeSub, setActiveSub] = useState();
  const [unlimited, setUnlimited] = useState();
  const [showRating, setShowRating] = useState(true);

  function handlePrompt() {
    setShowRating(false);
  }

  useEffect(() => {
    getGenerationLimit();
  });

  async function getGenerationLimit() {
    let token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      method: "get",
      url: "/api/v1/generation_limits",
    })
      .then((resp) => {
        setGenerationLimit(resp["data"].generations);
        setActiveSub(resp["data"].activeSub);
        setUnlimited(resp["data"].unlimited);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }

  function socialGenerated(results) {
    setSocials(results);
    setOutputToggle("social");
  }

  const updateDescription = (index, newResult) => {
    setDescriptions((prevResults) => {
      return prevResults.map((item, i) => {
        return i === index ? newResult : item;
      });
    });
  };

  return (
    <Formik
      initialValues={{
        propertyAddress: "",
        characterCount: "150",
        bedrooms: "",
        bathrooms: "",
        areaMeasurement: "sqFeet",
        areaSize: "",
        parking: "",
        transactionType: "",
        creativityMode: "",
        propertyType: "",
        generalDetails: "",
        kitchenDetails: "",
        bathroomDetails: "",
        roomDetails: "",
        outdoorDetails: "",
        miscellaneousDetails: "",
      }}
      validationSchema={GenerationSchema}
      onSubmit={async (data, { setSubmitting }) => {

        setSubmitting(true);
        //show the fields
        var descriptions = [{}, {}, {}]
        setDescriptions(descriptions)

        setOutputToggle("description");



        try {
          document.querySelectorAll(".descriptionOutput")[0].innerHTML == ""
          document.querySelectorAll(".descriptionOutput")[1].innerHTML == ""
          document.querySelectorAll(".descriptionOutput")[2].innerHTML == ""
        }
        catch (e) { }



        document.getElementById("generateButton").disabled = true;
        document.getElementById("generateButton").style.opacity = 0.5;



        getGenerationLimit();

        gtag("event", "generate_description_attempt", {
          propertyAddress: JSON.stringify(data["propertyAddress"]),
          transaction_type: JSON.stringify(data["transactionType"]),
          creativity_mode: JSON.stringify(data["creativityMode"]),
          propert_type: JSON.stringify(data["propertyType"]),
          bedrooms: JSON.stringify(data["bedrooms"]),
          bathrooms: JSON.stringify(data["bathrooms"]),
          parking: JSON.stringify(data["parking"]),
          areaSize: JSON.stringify(data["areaSize"]),
          areaMeasurement: JSON.stringify(data["areaMeasurement"]),
          word_count: JSON.stringify(data["characterCount"]),
          generalDetails: JSON.stringify(data["generalDetails"]),
          kitchenDetails: JSON.stringify(data["kitchenDetails"]),
          bathroomDetails: JSON.stringify(data["bathroomDetails"]),
          roomDetails: JSON.stringify(data["roomDetails"]),
          outdoorDetails: JSON.stringify(data["outdoorDetails"]),
          miscellaneousDetails: JSON.stringify(data["miscellaneousDetails"]),
        });
        const params = new URLSearchParams();

        params.append(
          "transactionType",
          JSON.stringify(data["transactionType"])
        );
        params.append("propertyType", JSON.stringify(data["propertyType"]));
        params.append(
          "propertyAddress",
          JSON.stringify(data["propertyAddress"])
        );
        params.append("bedrooms", JSON.stringify(data["bedrooms"]));
        params.append("bathrooms", JSON.stringify(data["bathrooms"]));
        params.append("areaSize", JSON.stringify(data["areaSize"]));
        params.append("areaMeasurement", JSON.stringify(data["areaMeasurement"]));

        params.append("parking", JSON.stringify(data["parking"]));
        params.append("wordCount", JSON.stringify(data["characterCount"]));
        params.append("generalDetails", JSON.stringify(data["generalDetails"]));
        params.append("kitchenDetails", JSON.stringify(data["kitchenDetails"]));
        params.append(
          "bathroomDetails",
          JSON.stringify(data["bathroomDetails"])
        );
        params.append("roomDetails", JSON.stringify(data["roomDetails"]));
        params.append("outdoorDetails", JSON.stringify(data["outdoorDetails"]));
        params.append("creativityMode", JSON.stringify(data["creativityMode"]));
        params.append(
          "miscellaneousDetails",
          JSON.stringify(data["miscellaneousDetails"])
        );

        let token = document.querySelector('meta[name="csrf-token"]').content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

        //track the generation (this used to do the generation, but now all it does is track that we consumed a credit)
        var descriptionGeneration = null;
        try {
          descriptionGeneration = await axios({
            method: "post",
            url: "/api/v1/descriptions",
            data: params,
          });

          gtag("event", "credit_tracking_success");
          console.log("Generation credit tracking complete")

        } catch (error) {
          //request failed, generation wasn't tracked, but that's not a big deal
          gtag("event", "credit_tracking_failed");
          console.log("Failed to track credits")
        }

        //check if the axios request was successful
        if (descriptionGeneration.status == 402) {
          console.log("Generation failed due to out of credits")

          setSubmitting(false);

          //if it wasn't, then we can't do the generation
          gtag("event", "description_generated_failure");

          //if the error was a 402, then we need to redirect to the pricing page
          console.log(descriptionGeneration.data)
          console.log(descriptionGeneration.status)
          console.log(descriptionGeneration.headers)
          gtag("event", "description_generated_failure_plan_expired")

          window.location.href = activeSub ? "pricing?outofsubgens" : "pricing?outofgens"
        } else {

          console.log("Starting generation")
          var prompt = [
            {
              "role": "system",
              "content": "You are a real estate agent copy writer. You're working on a listing, here are the details: " +
                "Address: " + data["propertyAddress"] +
                ", Transaction Type: " + data["transactionType"] +
                ", Property Type: " + data["propertyType"] +
                ", Bedrooms: " + data["bedrooms"] +
                ", Bathrooms: " + data["bathrooms"] +
                ", Parking spaces: " + data["parking"] +
                ", Size: " + data["areaSize"] + " " + data["areaMeasurement"] +
                ", General Details: " + data["generalDetails"] +
                ", Kitchen Details: " + data["kitchenDetails"] +
                ", Bathroom Details: " + data["bathroomDetails"] +
                ", Room Details: " + data["roomDetails"] +
                ", Outdoor Details: " + data["outdoorDetails"] +
                ", Miscellaneous Details: " + data["miscellaneousDetails"] +
                ", Writing style: " + data["creativityMode"] +
                ", Word Count: " + data["characterCount"]

            },
            {
              "role": "user",
              "content": `Write a ${data["characterCount"]} word listing description`
            },
          ];

          //ensure they're set to empty
          for (var i = 0; i < document.querySelectorAll(".descriptionOutput").length; i++) {
            document.querySelectorAll(".descriptionOutput")[i].innerHTML = "";
          }


          const eventSource1 = new EventSource(`https://app.mappedby.com/api/stream?model=gpt-3.5-turbo&key=listingai&message=${JSON.stringify(prompt)}`);
          const eventSource2 = new EventSource(`https://app.mappedby.com/api/stream?model=gpt-3.5-turbo&key=listingai&message=${JSON.stringify(prompt)}`);
          const eventSource3 = new EventSource(`https://app.mappedby.com/api/stream?model=gpt-3.5-turbo&key=listingai&message=${JSON.stringify(prompt)}`);

          eventSource1.onopen = function () {

          };

          eventSource1.onerror = function () {
            console.log("eventsource error");
          };

          eventSource2.onopen = function () {

          };

          eventSource2.onerror = function () {
            console.log("eventsource error");
          };

          eventSource3.onopen = function () {

          };

          eventSource3.onerror = function () {
            console.log("eventsource error");
          };

          const handleEventSource1 = function (e) {
            return genericHandler(e, 1, eventSource1, descriptionGeneration)
          }
          const handleEventSource2 = function (e) {
            return genericHandler(e, 2, eventSource2, descriptionGeneration)
          }
          const handleEventSource3 = function (e) {
            return genericHandler(e, 3, eventSource3, descriptionGeneration)
          }

          var submitCount = 0;

          const genericHandler = function (e, index, eventSource, descriptionGeneration) {

            if (e.data == "[DONE]") {
              eventSource.close();
              submitCount++;

              //add desc to the database
              var div = document.querySelectorAll(".descriptionOutput")[index - 1]
              const newDescriptionParams = {
                listing_id: descriptionGeneration.data[0].listing.id,
                description: div.innerHTML
              };

              //if it was, then we can do the generation
              gtag("event", "description_generated_success");

              axios({
                method: "post",
                url: "/api/v1/descriptions/" + descriptionGeneration.data[0].listing.id + "/add_description",
                data: newDescriptionParams,
              }).then((resp) => {
                //get the id of the description
                var id = resp.data[0].id;
                updateDescription(index - 1, { id: id, result: descriptions[index - 1].result })

                //re-enable the button
                if (submitCount >= 2) {

                  setShowRating(true);
                  setSubmitting(false);

                  //enable button again
                  document.getElementById("generateButton").disabled = false;
                  document.getElementById("generateButton").style.opacity = 1;
                }

              });



            } else {
              //console.log(index + " | " + JSON.parse(e.data).choices[0].delta.content)
              if (JSON.parse(e.data).choices[0].delta.role == "assistant" || JSON.parse(e.data).choices[0].delta.content == undefined) {
                return;
              } else {
                if (descriptions[index - 1].result === undefined)
                  descriptions[index - 1].result = "";

                descriptions[index - 1].result += JSON.parse(e.data).choices[0].delta.content;
                updateDescription(index - 1, { id: descriptions[index - 1].id, result: descriptions[index - 1].result })

                //var div = document.querySelectorAll(".descriptionOutput")[index-1];
                //div.innerHTML = descriptions[index-1].result
              }
            }
          };

          eventSource1.onmessage = handleEventSource1;
          eventSource2.onmessage = handleEventSource2;
          eventSource3.onmessage = handleEventSource3;
        }

      }}
    >
      {(formik) => (
        <Form>
          <MainContainer>
            <AttributeContainer>
              <Column>
                <Container>
                  <Title>Property Address</Title>
                  <InputDiv>
                    <Field
                      as={InputField}
                      type="string"
                      placeholder="Property address"
                      name="propertyAddress"
                      disabled={formik.isSubmitting}
                    />
                  </InputDiv>
                  <ErrorMessage component={ErrorText} name="propertyAddress" />
                </Container>
                <ButtonSelectorContainer
                  direction="column"
                  title="Property type"
                  type="propertyType"
                  isDisabled={formik.isSubmitting}
                />
                <ButtonSelectorContainer
                  title="Transaction type"
                  type="transactionType"
                  isDisabled={formik.isSubmitting}
                />
                <Container>
                  <Title>Description length (words)</Title>
                  <InputDiv>
                    <Field
                      as={InputField}
                      type="number"
                      min="0"
                      placeholder="Number of words (minimum 100 words)"
                      name="characterCount"
                      disabled={formik.isSubmitting}
                    />
                  </InputDiv>
                  <ErrorMessage component={ErrorText} name="characterCount" />
                </Container>
                <Container>
                  <Title>Basic house attributes</Title>
                  <BasicHouseAttribute isDisabled={formik.isSubmitting} />
                </Container>
                <ButtonSelectorContainer
                  title="AI Writing Creativity"
                  type="creativityMode"
                  description="Adjust how creative the AI should write. Neutral is recommended for increased accuracy."
                  hasDescription
                  isDisabled={formik.isSubmitting}
                />
              </Column>
              <Column>
                <FreeFlowContainers isDisabled={formik.isSubmitting} />
              </Column>
            </AttributeContainer>
            <GenerateButtonContainer>
              <GenerateButton id="generateButton" type="submit">
                {!formik.isSubmitting && "Generate description"}
                {formik.isSubmitting && <LoadingSpinner color="#fff" />}
              </GenerateButton>
              <LoadingText>
                {formik.isSubmitting && "This could take a minute"}

                {!formik.isSubmitting && !unlimited ? (
                  <p>
                    You have {generationLimit} generation(s) remaining.{" "}
                    {!activeSub &&
                      <a href="/pricing">See pricing plans</a>
                    }
                    {(activeSub && generationLimit == 0) &&
                      <a href="/pricing">Upgrade your plan</a>
                    }
                  </p>
                ) : (
                  ""
                )}
              </LoadingText>
            </GenerateButtonContainer>
            <OutputSelector
              descriptions={descriptions}
              selection={outputToggle}
              onChangeSelection={setOutputToggle}
            />
            {outputToggle == "description" && (
              <DescriptionOutput
                showRatingPrompt={showRating}
                handlePrompt={handlePrompt}
                descriptions={descriptions}
                type="description"
                socialGenerated={socialGenerated}
                blur="true"
                defaultValue=""
                activeSub={activeSub}
              />
            )}
            {outputToggle == "social" && (
              <DescriptionOutput
                descriptions={socials}
                type="social"
                regenerate="false"
                blur="true"
                defaultValue=""
                activeSub={activeSub}
              />
            )}
            <Footer />
          </MainContainer>
        </Form>
      )}
    </Formik>
  );
};

export default GeneratorTwo;
