import React from "react";
import styled from "styled-components";
import {
  Section,
  BlogContentContainer,
  Title,
  SubText,
  BlogContent,
  BlogSubtitle
} from "../style.js";
import { Link } from "react-router-dom";
import { blogData } from "./BlogData"

const BlogFooter = () => {

  const filterdDataTypes = blogData.filter(element => element.url !== window.location.pathname.split('/').pop())
  
  var sortedDataTypes = filterdDataTypes.sort(function() {
    return 0.5 - Math.random();
  });

  return (
    <>
      <hr style={{margin: "50px 0"}}/>
      <BlogSubtitle style={{marginBottom: "50px", textAlign: "center"}}>Related posts</BlogSubtitle>
      <BlogContentContainer>
        {sortedDataTypes.slice(filterdDataTypes,2).map((data, index) =>  (                    
          <BlogContent key={index}>
            <Link to={"/blog/" + data.url}><img src={require('images/' + data.image)} alt={data.alt} /></Link>
            <Link to={"/blog/" + data.url}><Title>{data.title}</Title></Link>
            <SubText>{data.subtext}</SubText>          
          </BlogContent>                          
        ))}
      </BlogContentContainer>      
    </>
  );
};

export default BlogFooter;
