import React, { useEffect, useState, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import styled from "styled-components";
import "./app.css";

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 100px;
  margin-top: 4em;
`;

const InnerContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #032231;
  min-height: 58px;

  & > * + * {
    margin-left: 60px;
  }
`;

const MenuItem = styled.div`
  border-bottom: ${(props) =>
    props.className == "active" && "5px solid #032231"};
  transition: border-bottom 0.2s ease-in-out;
`;

const OutputSelector = (props) => {
  const [descriptions, setDescriptions] = useState([{}, {}, {}]);

  useEffect(() => {
    setDescriptions(props.descriptions)
  }, [props.descriptions]);

  function handleChange(event) {

    if (descriptions && event == "social") {
      for (var i = 0; i < descriptions.length; ++i) {
        if (descriptions[i].id === undefined) {
          //if any of these have not been saved, don't allow the user to switch to social
          return;
        }
      }
    }
    props.onChangeSelection(event);
    gtag("event", "menu_toggle_" + event);
  }

  return (
    <Container>
      <InnerContainer>
        <MenuItem
          className={props.selection == "description" ? "active" : ""}
          onClick={() => handleChange("description")}
        >
          <p>Listing descriptions</p>
        </MenuItem>
        <MenuItem
          className={props.selection == "social" ? "active" : ""}
          onClick={() => handleChange("social")}
        >
          <p>Social media posts</p>
        </MenuItem>
      </InnerContainer>
    </Container>
  );
};

export default OutputSelector;
