import React, { useRef } from "react";
import Header from "./Header";
import HowItWorks from "./HowItWorks";
import SignupBottom from "./SignupBottom";
import Footer from "./Footer";
import ValueProps from "./ValueProps"
import CustomerImages from "./CustomerImages"
import WhatSection from "./WhatSection"
import styled from "styled-components";

const LandingContainer = styled.div`

`;

const useScroll = () => {
  const elRef = useRef(null);
  const executeScroll = () => {
    elRef.current.scrollIntoView();
    gtag("event", "lander_header_videocta_click");
  };

  return [executeScroll, elRef];
};

const LandingPage = () => {
  const [executeScroll, elRef] = useScroll();

  return (
    <LandingContainer>
      <Header scroll={executeScroll} />
      <WhatSection />
      <HowItWorks />
      <ValueProps />
      <CustomerImages />
      <SignupBottom />
      <Footer />
    </LandingContainer>
  );
};

export default LandingPage;
