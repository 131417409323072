import styled from "styled-components";

export const Section = styled.section`
  text-align: center;
  max-width: 65em;
  margin-left: auto;
  margin-right: auto;
  font-family: "Inter", sans-serif;
  padding-bottom: 6em;  
`;

export const WideSection = styled.section`
  text-align: center;
  max-width: 85em;
  margin-left: auto;
  margin-right: auto;
  font-family: "Inter", sans-serif;  
  background: #F3F7F9;
`;

export const WideSectionContainer = styled.section`
  background: #F3F7F9;
  padding-top: 60px;
`;

export const BlogHeaderSection = styled.section`
  text-align: center;
  max-width: 48em;
  margin-left: auto;
  margin-right: auto;
  font-family: "Inter", sans-serif;
  padding-bottom: 4em;
`;

export const DescCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #F3F7F9;
  margin: 60px 0 20px 0;  

  @media screen and (max-width: 780px) {
    flex-direction: column;
    text-align: center;
    padding: 1em;
    margin-top: 0;
  }
`;

export const DescSampleCardTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;  
  text-align: left;  
  width: 270px;
  margin-right: 20px;

  @media screen and (max-width: 780px) {
    width: 100% !important;
    text-align: center !important;
    align-items: center;
    margin: 20px 0 10px 0;
  }
`;

export const BlogPostSection = styled.section`
  text-align: left;
  max-width: 48em;
  margin-left: auto;
  margin-right: auto;
  font-family: "Inter", sans-serif;
  padding-bottom: 4em;
  line-height: 1.8;
  padding: 1em;

  & p {
    margin-bottom: 1.6em;
  }
`;

export const BasicSection = styled.section`
  text-align: center;
  max-width: 48em;
  margin-left: auto;
  margin-right: auto;
  font-family: "Inter", sans-serif;
  padding: 0 1em 0 1em;
`;

export const Content = styled.div`
  width: 85%;
  margin: auto;
`;

export const OutlineButton = styled.a`
  background: transparent;
  border: 4px solid #032231;
  border-radius: 10px;
  color: black;
  text-decoration: none;
  margin-right: 20px;
  padding: 14px;
  font-weight: 700;
  min-width: 180px;
  cursor: pointer;

  &:hover {
    background: #032231;
    color: #fff;
    text-decoration: none;
  }

  @media screen and (max-width: 780px) {
    margin-right: 0px;
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const CtaButton = styled.a`
  background: black;
  color: white;
  border: 4px solid #032231;
  border-radius: 10px;
  text-decoration: none;
  padding: 14px;
  font-weight: 700;
  min-width: 180px;
  cursor: pointer;

  &:hover {
    background: #e4e9eb;
    color: #032231;
    text-decoration: none;
  }

  @media screen and (max-width: 780px) {
    width: 70%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 780px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
  margin-top: 2em;

  @media screen and (max-width: 780px) {
    width: 100%;
  }
`;

export const BlogContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const HeaderText = styled.h1`
  font-size: 45px;
  font-weight: 900;
  margin-bottom: 20px;
`;

export const SubHeading = styled.h2`
  font-weight: 200;
  font-size: 20px;
  margin-bottom: 64px;
  line-height: 28px;
`;

export const MidHeading = styled.h2`
  font-size: 30px;
  font-weight: 900;
  line-height: 36px;
`;

export const HowItWorksHeading = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  justify-content: left;
  & > * {
    margin: 20px 0 20px 0;
  }
`;

export const HowItWorksNumber = styled.h3`
  font-size: 40px;
  font-weight: 700;
  line-height: 36px;
  justify-content: left;
  color: #007BFF;
`;

export const DescriptionSampleTitle = styled.h3`
  font-size: 40px;
  font-weight: 700;
  margin: 10px 0 10px 0;
`;

export const DescSampleSubHeading = styled.span`
  font-weight: 700;  
`;

export const DescSampleAddress = styled.p`  
  opacity: 0.8;
  font-size: 14px;

  @media screen and (max-width: 780px) {    
    width: 80%;
  }
`;

export const DescriptionSampleContent = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 36px;
  padding: 30px;  
  text-align: center;
  background: #fff;
  width: 500px;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin: 0;
  white-space: pre-line;

  @media screen and (max-width: 780px) {
    width: 100%;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  marg

  & > * {
    margin: 0 0 0 25px;
  }

  &:nth-child(1) {
    padding-bottom: 2em;
  }

  @media screen and (max-width: 780px) {
    flex-direction: column;

    & > * {
      margin: 25px 0 0 0;
    }
  }
`;

export const TextContainer = styled.div`
  flex-basis: 24%;
  line-height: 28px;
  text-align: left;
  padding: 0 20px 0 20px;

  &:nth-child(2) {
    padding: 0;
  }

  @media screen and (max-width: 780px) {    
    &:nth-child(2) {
      padding: 0 20px 0 20px;
    }
  }
`;

export const ImageContainer = styled.div`
  width: 50%;
  & img {
    width: 75%;
  }
  @media screen and (max-width: 780px) {
    width: 25%;

    &:nth-child(1) {
      order: 2;
    }
  }
`;

export const FooterList = styled.ul`
  list-style: none;
  display: inline-flex;
  float: left;
  padding: 0px;
  margin-bottom: 0px;

  & > * + * {
    margin-left: 10px;
  }

  & li > a {
    color: grey;
    text-decoration: none;
    font-weight: 200;
  }

  & li > a:hover {
    text-decoration: underline;
  }
`;

export const FooterText = styled.div`
  font-size: 12px;
  color: #00211d80;
  float: left;
  clear: both;
  margin-top: 1em;
  & a {
    color: grey;
    text-decoration: none;
    font-weight: 200;
  }

  & a:hover {
    text-decoration: underline;
  }
`;

export const Divider = styled.hr`
  height: 0px;
  border: 1px solid #032231;
  border-radius: 20px;
`;

export const FooterContainer = styled.div`
  margin: 0 auto;

  @media screen and (max-width: 780px) {
    padding: 0 1em 0 1em;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  margin-top: 45px;
  position: relative;

  & video {
    width: 100%;
    box-shadow: 0px 3.96235px 19.8118px rgba(0, 0, 0, 0.4);
    border-radius: 0 0 20px 20px;
  }
`;

export const PlayButton = styled.img`
  position: absolute;
  width: 150px;
  left: 50%;
  margin-left: -75px;
  top: 50%;
  margin-top: -75px;
  cursor: pointer;
  z-index: 100;
`;

export const VideoTopper = styled.div`
  width: 100%;
  background: #f3f7f9;
  margin: 0 auto;
  height: 35px;
  border-radius: 20px 20px 0 0;
  text-align: left;
  gap 8px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: -5px;
`;

export const Dot = styled.div`
  width: 16px;
  height: 16px;
  background: black;
  opacity: 0.1;
  border-radius 20px;
  display: inline-flex;

  &:nth-child(1) {
    margin-left: 14px;
  }
`;

export const ValuePropContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 45px;
  flex-wrap: wrap;

  & :nth-child(even) {    
    margin-left: 20px;
  }

  & div:nth-last-child(-n+2) {
    margin-top: 20px;    
  }

  @media screen and (max-width: 780px) {
    flex-direction: column;
    padding: 20px;

    & :nth-child(even) {    
      margin-left: 0px;
    }

    & > * + * {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
`;

export const ValuePropItem = styled.div`
  display: flex;
  flex-direction: column;
  background: #F3F7F9;
  border-radius: 10px;
  box-shadow: 0px 2px 4px #CEDFF8;
  padding: 20px;
  flex-basis: 34%;
  min-height: 162px;
  justify-content: center;
`

export const Title = styled.h4`
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 0.5em;
`;

export const SubText = styled.p`
  font-size: 15px;
  margin-bottom: 0px !important;
  min-width: 240px;
`

export const CustomerImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 45px;
  align-items: center;

  & > * + * {
    margin-left: 20px;
  }

  @media screen and (max-width: 780px) {
    flex-direction: column;
    padding: 20px;

    & > * + * {
      margin-top: 50px;
      margin-left: 0px;
    }
  }
`;

export const CustomerImageItem = styled.div`
  display: flex;
  flex-direction: column;

  & img {
    width: 200px;
  }
`;

export const BlogContent = styled.div`
  text-align: left;
  flex-basis: 48%;
  margin-bottom: 2em;

  & img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 12px;
    border: 2px solid #007bff33;
  }

  & a {
    color: #032231;
  }

  @media screen and (max-width: 580px) {
    flex-basis: 100%;
    margin-bottom: 36px;
  }
`;

export const BlogTitle = styled.h1`
  font-size: 45px;
  font-weight: 900;
  margin-bottom: 20px;
  text-align: left;
`;

export const BlogSubtitle = styled.h2`
  margin-bottom: 20px;
  font-size: 1.5em;
  font-weight: 600;
`;

export const Breadcrumbs = styled.ol`
  list-style: none;
  padding: 0;

  & li {
    display: inline;
  }

  & span {
    margin: 0 5px 0 5px;
  }
`;

export const BlogImage = styled.img`
  width: 100%;
  border-radius: 8px;
`;

export const H3 = styled.h3`
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: #212529;
  font-weight: 400;
`;

export const Quote = styled.p`
  font-style: italic;
  border-left: 4px solid black;
  padding-left: 20px;
  margin-left: 20px;
`;

export const TestimonialsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;

  @media screen and (max-width: 780px) {
    padding: 20px;
    flex-direction: column;

  }
`;

export const Testimonial = styled.div`
  background: #fff;
  width: 420px;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  &:nth-child(even) {
    margin-left: 20px;
  }

  @media screen and (max-width: 780px) {
    width: 100%;

    &:nth-child(even) {
      margin-left: 0px;
      margin-top: 80px;
    }
  }
`;

export const TestimonialTop = styled.div`
  margin-bottom: 10px;

  & img {
    margin-top: -45px;
  }
`;

export const TestimonalText = styled.div`
  padding: 20px;
  white-space: pre-wrap;
  text-align: center;
`;

export const TestimonialName = styled.p`
  font-weight: 600;
  margin: 8px 0 4px 0;
  font-size: 18px;
`;

export const TestimonialSubText = styled.p`
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 0px;
`;
