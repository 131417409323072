import React, { useEffect, useState, useRef } from "react";
import { Formik, Field, Form, useField, FieldArray } from "formik";
import axios from "axios";
import styled from "styled-components";
import "./app.css";
import LoadingSpinner from "./LoadingSpinner.js";
import RatingPrompt from "./RatingPrompt.js"
import {v4 as uuidv4} from "uuid";


const Section = styled.div`
  margin-top: 2em;
`;

const TextArea = styled.textarea`
  font-size: 14px;
  padding: 6px 8px;
  border: 1.5px solid #e4e9eb;
  background: #f3f7f9;
  width: 100%;
  min-height: 121px;
  height: 121px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  resize: vertical;  
  margin-bottom: 1em;
  filter: ${props => props.blur === "true" && !props.activeSub ? "blur(3px)" : "none"};
`;

const Container = styled.div`
  min-width: 320px;
  width: 100%;
  min-height: 250px;
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Description = styled.p`
  font-size: 16px;
  color: #032231;
  font-weight: 200;
  margin: 0 0 1em 0;
  font-family: "Inter", sans-serif;
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;

  & > * + * {
    margin-left: 15px;
  }
`;

const Button = styled.button`
  background: #f3f7f9;
  border: 1.5px solid #e4e9eb;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  &:hover {
    background: #ebf8ff;
  }
`;

const Placeholder = styled.p`
  opacity: 0.7;
  width: 100%;
  margin-bottom: 0px;
  text-align: center;
`;

const OutputContainer = styled.div`
  padding-bottom: 6em;
`;

const OutputUpsellDiv = styled.div`
  position: absolute;
  z-index: 100;
  margin-top: -55px;
`

const OutputUpsell = styled.a`  
  background: #032231;
  padding: 20px;
  border-radius: 20px;
  text-decoration: none !important;
  color: #fff;
  font-weight: 700;
  
  &:hover {
    opacity: 0.9;
    color: #fff;
  }
`

function handleClick(obj, type, descId) {
  obj.preventDefault(); //prevent behvaving like a submit button

  if (descId === undefined) {
    return;
  }

  // Copy text
  let range = document.createRange();
  let descriptionId = obj.target.getAttribute("id");
  let descriptionParent = document.getElementById(descriptionId)
  let descriptionTextArea = descriptionParent.firstChild
  range.selectNode(descriptionTextArea);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
  document.execCommand("copy");

  // Change text button copy
  let descriptionCopyButton = descriptionParent.querySelector('.copyButton')
  descriptionCopyButton.innerText = "Copied!";
  setTimeout(() => {
    descriptionCopyButton.innerText = "Copy";
  }, 1500);

  let token = document.querySelector('meta[name="csrf-token"]').content;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

  if (type == "description") {
    axios({
      method: "post",
      url: `/api/v1/descriptions/${descId}/copied`,
    })
      .catch(function (error) {
        if (error.response.status) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  } else if (type == "social") {
    axios({
      method: "post",
      url: `/api/v1/socials/${descId}/copied`,

    })
      .catch(function (error) {
        if (error.response.status) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }
}


const DescriptionOutput = (props) => {
  const [descriptions, setDescriptions] = useState(props.descriptions || []);
  const [modificationText, setModificationText] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [submittingId, setSubmittingId] = useState(null);
  const [listingId, setListingId] = useState(0)
  const [isModificationTextVisible, showModificationText] = useState(false);
  const isMounted = useRef(null);
  useEffect(() => {
    setListingId(props.listingId)
    setDescriptions(props.descriptions)
    if (document.querySelector(".descriptionOutput")) {
      document.querySelectorAll(".descriptionOutput").forEach((element) => {
        element.style.height = element.scrollHeight + "px";
      })
    }

    setTimeout(() => {
      let allDescriptions = document.querySelectorAll('.descriptionOutput')

      allDescriptions.forEach((description) => {

        description.addEventListener("copy", () => {
          if (props.type === "social") {
            gtag("event", "copy_social")
          } else if (props.type === "description") {
            gtag("event", "copy_description")
          }
        })
      })
    }, 100);

    // executed when component mounted
    isMounted.current = true;
    return () => {
      // executed when unmount
      isMounted.current = false;
    };
  }, [props.descriptions],
  );

  useEffect(() => {
    // Adjust heights after "Shorten" and "Lengthen"
    document.querySelectorAll(".descriptionOutput").forEach((element) => {
      element.style.height = "auto";
      element.style.height = element.scrollHeight + "px";
    });
  }, [descriptions]);

  function handleSocialClick(id, obj, descId, socialGenerated, setSubmitting) {
    obj.preventDefault();

    if (descId === undefined) {
      return;
    }

    setSubmitting(true);
    setSubmittingId(id);

    const params = new URLSearchParams();
    params.append("description_id", JSON.stringify(descId));

    let token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      method: "post",
      url: "/api/v1/socials",
      data: params,
    })
      .then((resp) => {
        socialGenerated(resp["data"]);
        setSubmitting(false);
      })
      .catch(function (error) {
        if (error.response.status) {
          setSubmitting(false);
          socialGenerated([JSON.stringify(error.response.status)]);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
    gtag("event", "social_generate_" + obj.target.getAttribute("id"));
  }

  function adjustHeight(event) {
    event.target.style.height = event.target.scrollHeight + "px";
  }

  const handleDescriptionChange = (event, index) => {
    const newDescriptions = descriptions.map((desc, descIndex) => {
      if (descIndex === index) {
        return { ...desc, result: event.target.value };
      }
      return desc;
    });
    setDescriptions(newDescriptions);
  };

  function PlaceholderDescription({ type }) {
    if (type === "description") {
      return <>
        Enter your house details above and click the{" "}
        <strong>Generate description</strong> button to see a custom
        description display here
      </>;
    } else if (type === "social") {
      return <>
        Click <strong>Generate social copy</strong> from a generated listing description to see a custom social copy displayed here
      </>;
    }
  }


  const performDescriptionOperation = (descriptionId, listingId, operationType, obj) => {
    obj.preventDefault();
    if (descriptionId === undefined) return;
    const params = new URLSearchParams({
      description_id: JSON.stringify(descriptionId),
      listing_id: JSON.stringify(listingId)
    });
    if(operationType==="modify") {
      params.append("modification_text", modificationText);
    }

    // Setup your token and headers as before
    let token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    const urlMap = {
      shorten: `/api/v1/descriptions/${descriptionId}/shorten_description`,
      lengthen: `/api/v1/descriptions/${descriptionId}/lengthen_description`,
      modify: `/api/v1/descriptions/${descriptionId}/modify_description`,
    };
    const requestUrl = urlMap[operationType];
    var descriptions = [{id: descriptionId, result: ""}]
    setDescriptions(descriptions);
    const channelName = uuidv4();
    params.append("channel", JSON.stringify(channelName));
    const pusher = new Pusher('30da35e03bd157814eff', {
      cluster: 'us2'
    });
    const channel = pusher.subscribe(channelName);
    channel.bind('description-generation', function (data) {
      const content = data.message.choices[0].delta.content;
      if (content === undefined) {
        pusher.disconnect();
      } else {
        setDescriptions(prevDescriptions => {
          return [
            {
              ...prevDescriptions[0],
              ["result"]: prevDescriptions[0].result + content,
            }
          ]
        });
      }
    });
    axios.post(requestUrl, params)
        .then((resp) => {
          setDescriptions(prevDescriptions => {
            return [
              {
                ...prevDescriptions[0],
                ["id"]: resp["data"]["updated_description"]["id"],
              }
            ]
          });
          props.updatePreviousDescriptions();
        })
        .catch(function (error) {
          if (error.response && error.response.status) {
            setSubmitting(false);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
  };

  return (
    <OutputContainer>
      {descriptions ? (
        <div>
          {descriptions.map((desc, index) => (
            <Section key={index}>
              <Container key={index} id={index}>
                {props.blur === "true" && index !== 0 && !props.activeSub && <OutputUpsellDiv><OutputUpsell href="/pricing">Subscribe now to access more descriptions</OutputUpsell></OutputUpsellDiv>}
                <TextArea
                  type="textarea"
                  name={desc.id}
                  id={desc.id}
                  className={props.type === "social" ? "descriptionOutputSocial" : "descriptionOutput"}
                  value={desc.result}
                  onChange={(e) => handleDescriptionChange(e, index)}
                  onKeyDown={adjustHeight}
                  blur={index !== 0 && !props.activeSub ? props.blur : ""}
                />
                <ButtonContainer>
                  <Button
                    disabled={descriptions[index].id === undefined || props.blur && index !== 0 && !props.activeSub ? true : false}
                    id={index}
                    onClick={(e) => handleClick(e, props.type, desc.id)}
                    purpose="copy"
                    type={props.type}
                    className="copyButton"
                  >
                    Copy
                  </Button>{" "}
                  {props.regenerate == "true" ? (
                    <Button
                      id={index}
                      key={index}
                      disabled={descriptions[index].id === undefined || props.blur && index !== 0 && !props.activeSub ? true : false}
                      onClick={(obj) =>
                        handleSocialClick(
                          index,
                          obj,
                          desc.id,
                          props.socialGenerated,
                          setSubmitting
                        )
                      }
                    >
                      {isSubmitting && submittingId == index ? (
                        <LoadingSpinner color="black" />
                      ) : (
                        "Generate social copy"
                      )}
                    </Button>
                  ) : (
                    ""
                  )}
                  {props.showUpdateTextButtons===true ? (
                    <div style={{
                      display: "flex",
                      gap: "10px",
                      marginLeft: "auto"
                    }}>
                      <Button
                          onClick={(e) => {
                            e.preventDefault();
                            performDescriptionOperation(desc.id, props.listingId, 'shorten', e)
                          }}
                          style={{background: "#ebf8ff"}}
                          disabled={isSubmitting && submittingId === desc.id}
                      >
                        Shorten
                      </Button>
                      <Button
                          onClick={(e) => {
                            e.preventDefault();
                            performDescriptionOperation(desc.id, props.listingId, 'lengthen', e);
                          }}
                          style={{background: "#ebf8ff"}}
                          disabled={isSubmitting && submittingId === desc.id}
                      >
                        Lengthen
                      </Button>
                      <Button
                        disabled={descriptions[index].id === undefined || props.blur && index !== 0 && !props.activeSub ? true : false}
                        id={"show_modify_" + index}
                        style={{background: "#ebf8ff"}}
                        onClick={(e) => {
                          e.preventDefault();
                          showModificationText(true);
                        }}
                        type={props.type}
                      >
                        Modify
                      </Button>
                    </div>
                  ):(
                      ""
                  )}
                </ButtonContainer>
                {props.showUpdateTextButtons===true && isModificationTextVisible && (
                  <>
                  <TextArea
                    type="textarea"
                    name={"modifyDescription"}
                    id="modifyDescription"
                    className={"social"}
                    placeholder="Describe how you would like the description to be modified. e.g. add more details about the kitchen, make the tone more casual, etc."
                    onChange={(e) => setModificationText(e.target.value)}
                    style={{ marginTop: "2em", minHeight: "60px", height: "60px" }}
                    maxLength={150}
                    value={modificationText}
                  />
                  <Button
                      onClick={(e) => {
                        e.preventDefault();
                        performDescriptionOperation(desc.id, props.listingId, 'modify', e);
                        showModificationText(false);
                      }}
                      disabled={isSubmitting && submittingId === desc.id}
                  >
                    Submit
                  </Button>
                  </>
                )}
              </Container>
            </Section>
          ))}
          {props.showRatingPrompt && <RatingPrompt hidePrompt={props.handlePrompt} descriptions={props.descriptions} />}
        </div>
      ) : (
        <Section>
          <Container>
            <Placeholder>
              <PlaceholderDescription type={props.type} />
            </Placeholder>
          </Container>
        </Section>
      )}
    </OutputContainer>
  );
};

DescriptionOutput.defaultProps = {
  regenerate: "true",
};

export default DescriptionOutput;
