import  React, { useEffect } from "react";
import styled from "styled-components";
import {
  BlogPostSection,
  BlogTitle,
  BlogSubtitle,
  Breadcrumbs,
  BlogImage,
  H3
} from "../style.js";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import BlogFooter from "./BlogFooter"

import HeaderImage from "images/aiblogpost.png";

const RealEstateAI = () => {

  return (
    <BlogPostSection>
        <Breadcrumbs>          
            <li><Link to="/">Home</Link></li>
            <span>/</span>
            <li><Link to="/blog">Blog</Link></li>
            <span>/</span>
            <li>Artificial Intelligence and Real Estate</li>          
        </Breadcrumbs>
        <BlogTitle>
          Artificial Intelligence and Real Estate
        </BlogTitle>

        <BlogImage src={HeaderImage} style={{marginBottom: "1.5em"}} alt="Man writing a real estate listing description with a pen on pads of paper" />

        <p>As Artificial Intelligence (AI) continues to rapidly evolve, there’s no surprise that industries across the board are beginning to implement it into their businesses. The real estate industry is no exception, with AI playing an increasingly important role in helping agents and brokers streamline operations, connect with potential buyers and sellers, and make better decisions when buying or selling property. In this post, we’ll take a look at some of the ways AI is being used in real estate and how it’s impacting the industry as a whole. </p>

     
        <BlogSubtitle>What is Artificial Intelligence?</BlogSubtitle>
        <p>Artificial Intelligence is a branch of computer science that deals with creating intelligent machines that can work and react like humans. Some of the activities computers with artificial intelligence are designed for include: understanding natural language spoken by humans; recognizing objects, facial expressions and scenes; making decisions on their own; and improving their own performance by learning from experience. Scientists and researchers continue to develop new ways to make computers more intelligent, which brings us closer to achieving true artificial general intelligence—the creation of a machine that can perform any intellectual task that a human being can. While we are still some way off from this goal, significant progress has been made in the field of AI in recent years. Thanks to ongoing research and development, the potential applications of artificial intelligence are becoming increasingly exciting and diverse.</p>

        <BlogSubtitle>AI Applications in Real Estate</BlogSubtitle>

        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>Marketing Listings Through AI</H3>
        <p>Real estate agents are always looking for new ways to market homes and attract buyers. With the advent of artificial intelligence, there are now new opportunities for agents to use this technology to their advantage. One way AI is being used in real estate is through virtual home tours. These tours can be created using photos and videos of the property, and they can be realistic enough to give potential buyers a good sense of what the home looks like (more on that below). AI can also be used to create targeted marketing campaigns. For example, if an agent knows that a potential buyer is interested in homes with three bedrooms, they can use AI to send them listings that match that criteria as well as other listings they may be intersted in. By using AI to its full potential, real estate agents can make the home buying process easier and more efficient for everyone involved.</p>
        <p>3D models of homes are becoming increasingly popular, as they offer a realistic way to preview what a property will look like before it is built. While traditional methods of creating 3D models can be time-consuming and expensive, AI is beginning to change that. Using a technique called photogrammetry, AI-powered software can create 3D models from a series of 2D images. This process is much faster and more affordable than traditional methods, making it accessible to a wider range of people. In addition, AI can be used to generate realistic renderings of how a home will look when it is completed. This allows potential buyers to get a better sense of what the property will look like before making a decision. Ultimately, AI is changing the way we design and build homes, making the process more efficient and less costly.</p>

        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>Identifying Issues Before Buying </H3>
        <p>Artificial Intelligence is helping to identify potential problem areas in homes before they're listed for sale. By analyzing data from past home sales, AI can uncover patterns that may indicate potential issues with a property. For example, if a home has been listed for sale several times in the past year but has never sold, this could be an indication that there are problems with the property that buyers are unaware of. By bringing these potential problems to the attention of sellers before their homes are listed, AI can help avoid costly delays down the line. In addition, this can help to ensure that properties are in good condition before they're listed which can help to boost confidence in the housing market and encourage more people to put their homes on the market. Ultimately, by making the process of buying and selling homes more efficient, AI can help to create a healthier housing market for everyone involved.</p>
        
        <BlogSubtitle>Overall</BlogSubtitle>
        <p>Artificial Intelligence is being used more and more in the real estate industry to help with tasks such as finding properties, analyzing data, and providing recommendations. The use of AI can help agents be more efficient and provide a better customer experience. While Artificial Intelligence is still relatively new in the housing space, it will definitely be a key piece of technology that all agents and buyers need to keep their eye on.  </p>

        <BlogFooter />
        <Footer />
    </BlogPostSection>
    
  );
};

export default RealEstateAI;
