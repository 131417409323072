import  React, { useEffect } from "react";
import styled from "styled-components";
import {
  BlogPostSection,
  BlogTitle,
  BlogSubtitle,
  Breadcrumbs,
  BlogImage,
  H3
} from "../style.js";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import BlogFooter from "./BlogFooter"

import HeaderImage from "images/organizedagent.jpg";

const OrganizedAgent = () => {

  return (
    <BlogPostSection>
        <Breadcrumbs>          
            <li><Link to="/">Home</Link></li>
            <span>/</span>
            <li><Link to="/blog">Blog</Link></li>
            <span>/</span>
            <li>Tips For Organizing Your Real Estate Business</li>          
        </Breadcrumbs>
        <BlogTitle>
        Tips For Organizing Your Real Estate Business
        </BlogTitle>

        <BlogImage src={HeaderImage} style={{marginBottom: "1.5em"}} alt="Organized work desk" />

        <p>As a real estate agent, you know that organization is key to success. You need to be able to juggle multiple tasks at once, keep track of important deadlines, and always be one step ahead of your competition. That's why we've put together a list of five powerful ways you can use to organize your real estate business. From utilizing contact management software to staying on top of the latest industry news, these tips will help you streamline your business and stay ahead of the game.</p>

        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>Use A Contact Management System</H3>
        <p>A contact management system (CMS) is a must-have for any real estate agent. It allows you to keep track of your clients, properties, and potential leads in one centralized location. This way, you can easily access any information you need without having to search through piles of paperwork. There are many different CMS programs available on the market, so be sure to do your research and find one that fits your specific needs.</p>

        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>Stay Up-To-Date On Industry News </H3>
        <p>In order to succeed in the real estate industry, it's important that you stay up-to-date on the latest news and trends. This will help you anticipate changes in the market and adapt your business accordingly. There are many ways you can stay informed, including reading industry publications, listening to podcasts, and attending conferences and events.</p>
        
        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>Utilize Social Media</H3>
        <p>Social media is a powerful tool that can be used for both personal and business purposes. In terms of your real estate business, social media can be used to promote listings, connect with potential leads, and build relationships with clients. Be sure to post regularly and interact with your followers to maximize the reach of your social media campaigns.</p>
        
        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>Stay Organized Financially</H3>
        <p>As a real estate agent, it's important that you stay on top of your finances. This includes tracking your income and expenses, maintaining accurate records, and staying up-to-date on tax laws and deductions. Utilizing accounting software can help make financial organization easier and less time-consuming. Most common applications would include Quickbooks, Xero or even just a free well built spreadsheet.</p>

        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>Have A Solid Marketing Strategy</H3>
        <p>Last but not least, having a solid marketing strategy is essential for any successful business—and real estate is no exception. Your marketing strategy should include both online and offline components, such as an updated website, targeted social media campaigns, print ads, direct mailers, etc. By utilizing a mix of marketing tactics, you'll be able to reach a wider audience and close more deals.</p>

        <BlogSubtitle>Conclusion</BlogSubtitle>
        <p>There you have it—five powerful tips for organizing your real estate business! By implementing these tips into your daily routine, you'll be able to streamline your operation and focus on what's really important: closing deals and making money!</p>
        
        <BlogFooter />
        <Footer />
    </BlogPostSection>
    
  );
};

export default OrganizedAgent;
