import React from "react";
import styled from "styled-components";
import { BasicSection, Divider, Content } from "./style.js";
import Footer from "./Footer.js";

const PrivacyPolicy = () => {
  return (
    <BasicSection style={{ textAlign: "left" }}>
      <Content>
        <h2>Privacy Policy</h2>
        <br />
        <p>
          The information below is meant to explain in plain language what
          information we collect, how we use that information, and why.
        </p>
        <p>
          If you have any additional questions or concerns after reviewing this
          policy, please reach out to us directly via email at
          <a href="mailto:hello@listing-ai.herokuapp.com"> hello@listing-ai.herokuapp.com</a>
        </p>
        <br />
        <Divider />
        <br />
        <p>
          <strong>What information are we collecting?</strong>
        </p>
        <p>
          We collect basic anonymized usage analytics data using Google
          Analytics – this may include tracking the number of active users, and
          details about page visits and clicks.
        </p>
        <p>
          <i>
            {" "}
            Anonymized data means that it cannot be directly connected to a
            specific person
          </i>
        </p>
        <p>
          We will also store inputed data used to generate descriptions on our
          platform – this includes all required data fields.{" "}
        </p>
        <p>
          <strong>How is that information used?</strong>
        </p>
        <p>
          All the data collected is used simply for analytics and reporting
          purposes to help us better understand how our users are using our
          application, and allow us to make product decisions on how to improve
          the application.
        </p>
        <p>
          We will only ever use emails to contact you about important updates in
          relation to our service.
        </p>
        <p>
          <strong>How can I contact you?</strong>
        </p>
        <p>
          You can reach us directly via email{" "}
          <a href="mailto:hello@listing-ai.herokuapp.com">hello@listing-ai.herokuapp.com</a>
        </p>
        <p>
          <i>Last updated April 5th, 2021</i>
        </p>
        <br></br>
      </Content>
      <Footer />
    </BasicSection>
  );
};

export default PrivacyPolicy;
