import React from "react";
import styled from "styled-components";
import {
  Section,
  OutlineButton,
  HeaderButton,
  FooterText,
  FooterList,
  FooterContainer,
  Divider,
} from "./style.js";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Section>
      <FooterContainer>
        <Divider />
        <div>
          <FooterList>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <a href="/pricing?">Pricing</a>
            </li>
            <li>
              <Link to="/faq?">FAQ</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>
            <li>
              <Link to="/terms">Terms</Link>
            </li>
            <li>
              <a href="mailto:hello@listing-ai.herokuapp.com">Contact</a>
            </li>
          </FooterList>
        </div>
        <FooterText>
          <p>
            Made with ❤️ in 🇨🇦
          </p>
        </FooterText>
      </FooterContainer>
    </Section>
  );
};

export default Footer;
