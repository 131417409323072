import React, { useEffect } from "react";
import styled from "styled-components";
import {
  BlogPostSection,
  BlogTitle,
  BlogSubtitle,
  Breadcrumbs,
  BlogImage
} from "../style.js";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import BlogFooter from "./BlogFooter"

import HeaderImage from "images/bestRealtorToolsImage.png";
import StreetText from "images/streetTextImage.png"
import Canva from "images/canvaImage.png"
import Mailchimp from "images/mailchimpImage.png"
import Docusign from "images/docusignImage.png"
import ListingAi from "images/listingAiImage.png"
import ParkBench from "images/parkBenchImage.png"
import Calendly from "images/calendlyImage.png"

const BestTools = (props) => {

  return (
    <BlogPostSection>
        <Breadcrumbs>          
            <li><Link to="/">Home</Link></li>
            <span>/</span>
            <li><Link to="/blog">Blog</Link></li>
            <span>/</span>
            <li>7 Best Tools For Tech Focused Realtors in 2023</li>          
        </Breadcrumbs>
        <BlogTitle>
            7 Best Tools For Tech Focused Realtors in 2023
        </BlogTitle>

        <BlogImage src={HeaderImage} style={{marginBottom: "1.5em"}} alt="Two men stare at a laptop together on a couch" />

        <p>Real estate is a competitive business. Every realtor needs to develop their tools belt to include as many tools as possible. This tool belt is ever-evolving and has a singular purpose; to help you stay ahead (or get ahead) by helping your clients buy/sell their properties.</p>
        <p>In this article, we will discuss a list of tools that help realtors save time and focus on what matters; building relationships and trust.</p>

        <BlogSubtitle><a target="_blank" href="https://streettext.com/">#1 Lead generation: StreetText</a></BlogSubtitle>
        <BlogImage src={StreetText} alt="Street Text landing page image" />
        <p>Real estate is a relationship industry but the relationships start as leads. StreetText helps you build leads and turn them into customers by leveraging artificial intelligence and Facebook ads to target leads that are more likely to convert for you as efficiently as possible.</p>

        <BlogSubtitle><a target="_blank" href="https://www.canva.com/">#2 Design: Canva</a></BlogSubtitle>
        <BlogImage src={Canva} alt="Canva landing page image" />
        <p>The world is rewarding generalist practitioners as we continue to seek independence. Real estate agents are ahead of the curve when it comes to that. Designing creative social media posts is an important skill that every agent should master.  According to a recent <Link to="https://www.nar.realtor/sites/default/files/documents/2018-real-estate-in-a-digital-world-12-12-2018.pdf">study from the National Association of Realtors</Link>, 47% of real estate businesses note that social media results in the highest quality leads versus other sources and the design of your posts are at the center of it.</p>
        <p>Canva is by far the easiest graphic design tool that you can leverage and the best part is you can do everything directly in the browser. Canva also has thousands of sample templates you can use to get up and running quickly.</p>

        <BlogSubtitle><a target="_blank" href="https://mailchimp.com/">#3 Marketing Automation: Mailchimp</a></BlogSubtitle>
        <BlogImage src={Mailchimp} alt="Mailchimp landing page image" />
        <p>Having a list of customer emails is fantastic, as email is still king and will be for a long time. Though managing those connections and keeping your leads engaged is a completely different story, and can often be time consuming and tedious. </p>
        <p>Mailchimp makes email marketing simple without any need for a designer or a developer. You can send monthly newsletters or create custom rules for when to communicate to specific groups. The platform also offers retargeting tools for your leads with social media ads or emails.</p>

        <BlogSubtitle><a target="_blank" href="https://www.docusign.com/">#4 E-signatures: DocuSign</a></BlogSubtitle>
        <BlogImage src={Docusign} alt="Docusign landing page image" />
        <p>If the 2021 pandemic revealed anything to us, it’s that people prefer digital communication at their own convenience when it comes to the tedious parts of any process – this includes buying and selling homes. In today’s world, nobody would even consider driving to an office just to sign a few papers and DocuSign is the solution.</p>
        <p>DocuSign is a great e-signature service that reduces paperwork and saves a ton of time. It notifies agents when important documents have been viewed and signed by their clients. It is also the official provider for the National Association of Realtors and its signatures are legally binding in all 50 states in the US.</p>

        <BlogSubtitle><a target="_blank" href="/?utm_source=blog&utm_medium=organic&utm_campaign=seo_focused_blogs&campaign_content=7_best_tools_for_tech_focused_realtors">#5 Copywriting: ListingAI</a></BlogSubtitle>
        <BlogImage src={ListingAi} alt="ListingAI landing page image" />
        <p>Copywriting is the most important skill in any business. Attention catching copywriting starts conversations, conversations become leads, and leads turn into relationships. Amongst all the sections above, copywriting might be the most important one (we are a bit biased though).</p>
        <p>Writing engaging and converting property descriptions can be challenging. Agents can resort to copying descriptions from older listings for the same property, or find descriptions from listings around the area. Oftentimes we see realtors will pay copywriters a hefty fee for good descriptions. Some agents choose to write the description themselves, which can take a varying amount of time depending on how good of a writer the agent is (valuable time which could be spent helping clients).</p>
        <p>ListingAI offers an alternative that leverages the power of artificial intelligence. You can generate listing descriptions by simply providing the basic attributes of the property and any details that you think are worth sharing and get 3 results to choose from and build on top of in seconds.</p>

        <BlogSubtitle><a target="_blank" href="https://parkbench.com/">#6 Community management: Parkbench</a></BlogSubtitle>
        <BlogImage src={ParkBench} alt="Parkbench landing page image" />
        <p>The top realtors are community leaders. They focus on a small geographical area and build meaningful connections with people in that area.</p>
        <p>Parkbench helps agents become those hyperlocal community leaders. It offers a way for people to find events, stories, and news for their neighborhood.</p>

        <BlogSubtitle><a target="_blank" href="https://calendly.com/">#7 Scheduling tool: Calendly</a></BlogSubtitle>
        <BlogImage src={Calendly} alt="Calendly landing page image" />
        <p>Agents are busy, they have many jobs to be done. One of the most important ones is scheduling time with their clients to view units. That's where Calendly comes in. You can mark your availability on your calendar, connect it to Calendly then send your clients your Calendly link. There they can book the slots that work best for them. No more back and forth trying to find a time that works for both parties.</p>
        <p>Did we miss a tool that you can't live without? We'd love to hear about it! Send us an email and we'll add it to the list – hello@listing-ai.herokuapp.com</p>
        
        <BlogFooter />
        <Footer />
    </BlogPostSection>
    
  );
};

export default BestTools;
