import React, { useState } from "react";
import { Formik, Field, Form, useField, FieldArray } from "formik";
import {
  Button,
  TextField,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import axios from "axios";
import styled from "styled-components";
import "../app.css";

const Results = styled.div`
  background-color: #dadded;
  padding: 24px;
  min-height: 100%;
  border-radius: 10px;
`;

const Container = styled.div`
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
`;

const MyRadio = ({ label, ...props }) => {
  const [field] = useField(props);

  return <FormControlLabel {...field} control={<Radio />} label={label} />;
};

const MyTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <br></br>
      <label htmlFor={props.id || props.name}>{label}</label>
      <div>
        <textarea {...field} {...props} />
      </div>
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </>
  );
};

const Generator = () => {
  const [description, setDescription] = useState();

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-5">
            <h1>Listing.ai</h1>
            <h4 className="subHeading">
              Spend less time writing and more time selling houses
            </h4>
          </div>
        </div>

        <div className="row">
          <div className="col-md-5">
            <Formik
              initialValues={{
                description: "",
                propertyType: [],
                attributes: [
                  { type: "bedrooms", name: "", id: "" + Math.random() },
                ],
              }}
              onSubmit={(data) => {
                console.log(data);
/*
                const params = new URLSearchParams();
                params.append(
                  "description",
                  JSON.stringify(data["description"])
                );
                params.append(
                  "transactionType",
                  JSON.stringify(data["transactionType"])
                );
                params.append(
                  "propertyType",
                  JSON.stringify(data["propertyType"])
                );
                params.append("attributes", JSON.stringify(data["attributes"]));

                axios({
                  method: "post",
                  url: "/api/v1/descriptions",
                  data: params,
                })
                  .then((resp) => setDescription(resp["data"]))
                  .catch((resp) => console.log(resp));

*/
                var address = "153 strachan ave toronto ontario";

                var prompt = [
                  {
                      "role" : "system",
                      "content" : "this is a big house2"
                  },
                  {
                      "role" : "user",
                      "content" : "Write a listing description for: " + address
                  },
                ];



                const eventSource = new EventSource(`https://app.mappedby.com/api/stream?model=gpt-3.5-turbo5&key=listingai&message=${JSON.stringify(prompt)}`);

                eventSource.onopen = function(){
                    console.log("eventsource opened");
                };

                eventSource.onerror = function(){
                    console.log("eventsource error");
                };


                eventSource.onmessage = function (e) {
                    if(e.data == "[DONE]") {
                        eventSource.close();

                        //enable button again
//                        document.getElementById("generate").setAttribute("style","opacity:1")
//                        document.getElementById("generate").onclick = requestResponse;
                    } else {
                        if(JSON.parse(e.data).choices[0].delta.role == "assistant" || JSON.parse(e.data).choices[0].delta.content == undefined) {
                            return;
                        } else { 
                            if (document.querySelector(".descriptionOutput")) {      
                              var div = document.querySelectorAll(".descriptionOutput")[0];
                              div.innerHTML += JSON.parse(e.data).choices[0].delta.content;
                            }
                        }
                    }
                };
              }}

              
            >
              {({ values }) => (
                <Form>
                  <div className="promptHeading">Property Type:</div>
                  <MyRadio
                    name="propertyType"
                    type="radio"
                    value="Residential"
                    label="Residential"
                  />
                  <MyRadio
                    name="propertyType"
                    type="radio"
                    value="Commercial"
                    label="Commercial"
                  />
                  <MyRadio
                    name="propertyType"
                    type="radio"
                    value="Mixed"
                    label="Mixed"
                  />

                  <div className="promptHeading">Transaction Type:</div>
                  <MyRadio
                    name="transactionType"
                    type="radio"
                    value="For Rent"
                    label="For Rent"
                  />
                  <MyRadio
                    name="transactionType"
                    type="radio"
                    value="For Sale"
                    label="For Sale"
                  />

                  <div className="promptHeading">House Attributes:</div>
                  <FieldArray name="attributes">
                    {(arrayHelpers) => (
                      <div>
                        {values.attributes.map((attribute, index) => {
                          return (
                            <div className="attribute" key={attribute.id}>
                              <Field
                                name={`attributes.${index}.type`}
                                type="select"
                                as={Select}
                              >
                                <MenuItem value="year">build year</MenuItem>
                                <MenuItem value="area">area</MenuItem>
                                <MenuItem value="bedrooms">bedrooms</MenuItem>
                                <MenuItem value="bathrooms">bathrooms</MenuItem>
                                <MenuItem value="demographic">demographic</MenuItem>
                              </Field>

                              <Field
                                placeholder="value"
                                name={`attributes.${index}.name`}
                                as={TextField}
                                className="attributeValueInput"
                              />
                              <Button
                                color="secondary"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                Remove
                              </Button>
                            </div>
                          );
                        })}
                        <Button
                          variant="outlined"
                          color="default"
                          className="addAttributeButton"
                          onClick={() =>
                            arrayHelpers.push({
                              type: "extras",
                              name: "",
                              id: "" + Math.random(),
                            })
                          }
                        >
                          add attribute
                        </Button>
                      </div>
                    )}
                  </FieldArray>

                  <MyTextArea
                    name="description"
                    label="Additional Details:"
                    className="additionalDetails"
                    rows="6"
                    placeholder="Write any details you want to highlight on the property here.."
                  />

                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                    >
                      Generate Description
                    </Button>
                  </div>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </Form>
              )}
            </Formik>
          </div>

          <div className="col-md-7">
            <Results>
              <h5>Result:</h5>
              <hr></hr>
              <p>{description}</p>
            </Results>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Generator;
