import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, isSubmitting } from "formik";
import axios from "axios";
import styled from "styled-components";
import "./app.css";
import { ErrorText, Container } from "./GeneratorTwo.js";

const Title = styled.p`
  font-size: 16px;
  color: #032231;
  font-weight: 700;
  margin: 0 0 1em 0;
  font-family: "Inter", sans-serif;
`;

const InnerContainer = styled.div`
  flex-direction: column;
`;

const TextArea = styled.textarea`
  font-size: 14px;
  padding: 6px 8px;
  border: 1.5px solid #e4e9eb;
  background: #f3f7f9;
  width: 100%;
  height: 150px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  resize: vertical;
`;

const Counter = styled.span`
  position: absolute;
  bottom: 4px;
  right: 18px;
  font-size: 12px;
  opacity: 0.5;

  & span {
    margin: 0 1px 0 1px;
  }
`;

export const InputDiv = styled.div`
  margin-bottom: 0px !important;
  flex-direction: column;
  position: relative;
`;

const FreeFlowContainers = (props) => {
  const [count, setCount] = useState({
    generalDetails: "0",
    kitchenDetails: "0",
    bathroomDetails: "0",
    roomDetails: "0",
    outdoorDetails: "0",
    miscellaneousDetails: "0",
  });

  function handleCount(event) {
    const value = event.target.value;
    const length = value.length;
    const name = event.target.name;

    setCount({
      ...count,
      [name]: length,
    });
  }

  const dataTypes = [
    {
      label: "General details",
      placeholder:
        "Recently renovated, remodeled, spacious, duplex, modern architecture...",
      name: "generalDetails",
    },
    {
      label: "Kitchen details",
      placeholder:
        "Quartz counters, gas range, stainless steel appliances, kitchen island...",
      name: "kitchenDetails",
    },
    {
      label: "Bathroom details",
      placeholder: "Dual vanity, sauna, guest bath on main floor...",
      name: "bathroomDetails",
    },
    {
      label: "Room details",
      placeholder:
        "Fireplace in living room, freshly painted, hardwood floors...",
      name: "roomDetails",
    },
    {
      label: "Outdoor details",
      placeholder: "Garden patio, balcony, private pool...",
      name: "outdoorDetails",
    },
    {
      label: "Miscellaneous details",
      placeholder:
        "Plumbing recently upgraded, 24 hour security, gated community, shared gym..",
      name: "miscellaneousDetails",
    },
  ];

  return (
    <Container>
      {dataTypes.map((data, index) => (
        <InnerContainer key={index}>
          <Title>{data["label"]}</Title>
          <InputDiv>
            <Field
              as={TextArea}
              type="textarea"
              placeholder={data["placeholder"]}
              name={data["name"]}
              disabled={props.isDisabled}
              onKeyUp={handleCount}
              maxLength="200"
            />
            <Counter>
              {count[data["name"]]}
              <span>/</span>200
            </Counter>
          </InputDiv>
          <ErrorMessage component={ErrorText} name={data["name"]} />
        </InnerContainer>
      ))}
    </Container>
  );
};

export default FreeFlowContainers;
