import React from "react";
import styled from "styled-components";
import {
  TestimonialsContainer,
  Testimonial,
  TestimonialTop,
  TestimonalText,
  TestimonialName,
  TestimonialSubText
} from "./style.js";

const Testimonials = (props) => {
  const dataTypes = [
    {
      image: "hugoMahoneyImage.png",
      name: "Hugo Mahoney",
      subText: "Founder at Love Property",
      text: "We had been waiting for someone to apply machine learning to real estate descriptions! \n \nWorking closely with the ListingAI team we used our AI-generated descriptions to achieve an enormous boost to our Google search rankings.",
    },
    {
      image: "timothyZwartImage.png",
      name: "Timothy Zwart",
      subText: "Agent at Intracoastal Realty",
      text: "I freaking love this thing! I absolutely despise writing up these descriptions so I’ll be using ListingAI a lot. I’ve already told 3 other realtors about it!",
    },    
  ];

  return (
  <TestimonialsContainer>
    {dataTypes.map((data, index) => (
      <Testimonial key={index}>
        <TestimonialTop>
          <img src={require('images/' + data.image)} />
          <TestimonialName>{data.name}</TestimonialName>
          <TestimonialSubText>{data.subText}</TestimonialSubText>
        </TestimonialTop>
        <TestimonalText>
          <p>{data.text}</p>
        </TestimonalText>  
      </Testimonial>
    ))}
  </TestimonialsContainer>
  );
};

export default Testimonials;
