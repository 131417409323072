import React, { useState, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import styled from "styled-components";
import "./app.css";

const TextArea = styled.textarea`
  font-size: 14px;
  padding: 6px 8px;
  border: 1.5px solid #e4e9eb;
  background: #f3f7f9;
  max-width: 400px;
  width: 100%;
  min-height: 100px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  resize: vertical;
  margin-bottom: 12px;
`;

const SubmitButton = styled.div`
  background: #032231;
  color: #fff;
  border-radius: 20px;
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  justify-content: center;
  padding: 18px;
  display: flex;
  border: none;
  cursor: pointer;
  font-weight: 700;
  &:hover {
    opacity: 0.8;
  }
  outline: none;
  &:focus {
    outline: 0;
  }
  @media screen and (max-width: 634px) {
    width: 100%;
  }
`;

const InputContainer = styled.div`  
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const FeedbackSubmit = (props) => {
  function handleSubmit(e) {
    e.preventDefault();
    const feedbackValue = document.getElementById("ratingInput").value;
    props.setFeedback(feedbackValue)
    props.nextStep();
  }

  return (
   <div>
    <InputContainer id="inputContainer">
      <p>What can we do to improve ListingAI?</p>
      <Field
        as={TextArea}
        type="textarea"
        id="ratingInput"
        placeholder="Is there anything we can do to improve the tool?"
        name="feedbackInput"
      />
      <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
    </InputContainer>
   </div>
  );
};

export default FeedbackSubmit;