import  React, { useEffect } from "react";
import styled from "styled-components";
import {
  BlogPostSection,
  BlogTitle,
  BlogSubtitle,
  Breadcrumbs,
  BlogImage,
  H3
} from "../style.js";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import BlogFooter from "./BlogFooter"

import HeaderImage from "images/citypano.jpg";

const BestCities = () => {

  return (
    <BlogPostSection>
        <Breadcrumbs>          
            <li><Link to="/">Home</Link></li>
            <span>/</span>
            <li><Link to="/blog">Blog</Link></li>
            <span>/</span>
            <li>The 5 Best Cities for Real Estate Agents</li>          
        </Breadcrumbs>
        <BlogTitle>
        The 5 Best Cities for Real Estate Agents
        </BlogTitle>

        <BlogImage src={HeaderImage} style={{marginBottom: "1.5em"}} alt="Sunny City Landscape" />

        <p>If you're a real estate agent, then you know that where you live and work can have a big impact on your career. Some cities are just better for real estate agents than others, offering more opportunity, higher pay, and a better quality of life. In this blog post, we'll countdown the five best cities for real estate agents to live and work in.</p>

        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>#5 Denver, Colorado</H3>
        <p>As one of the fastest-growing cities in the United States, it's no surprise that Denver makes our list. The Mile High City is attracting transplants from all over the country thanks to its booming economy, abundance of outdoor recreation, and thriving cultural scene. For real estate agents, there are plenty of opportunities to cash in on Denver's growth. The median home price in Denver is $400,000, and prices are only going up. So if you're looking for a city with plenty of potential, Denver is definitely worth considering.</p>


        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>#4 Seattle, Washington </H3>
        <p>Like Denver, Seattle is also experiencing rapid growth thanks to an influx of new residents and businesses. But what really sets Seattle apart is its strong housing market. Despite being one of the most expensive housing markets in the country, homes in Seattle are selling like hotcakes. In fact, the average home in Seattle spends just 14 days on the market before being sold—one of the shortest turnaround times in the nation. If you're looking to make a quick sale, then Seattle is definitely the place to be.</p>


        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>#3 Austin, Texas</H3>
        <p>If you're looking for a city with a strong job market and a vibrant culture, then Austin should be at the top of your list. The Live Music Capital of the World is also attracting attention from some of the biggest tech companies in the world—including Google, Apple, and Facebook—who are investing heavily in Austin's burgeoning tech scene. And as more people move to Austin for work or pleasure, the demand for housing is only going to increase. So if you want to be at the forefront of Austin's growth, there's no time like the present to get started in real estate here.</p>


        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>#2 Boston, Massachusetts</H3>
        <p>Boston has always been one of the most desirable places to live on the East Coast thanks to its rich history and abundance of culture. But in recent years, Boston has also become one of the hottest real estate markets in the country. Thanks to a booming economy and an influx of young professionals moving to Boston for work or school, prices here are higher than ever before—and they're only going up. If you're looking for a city with long-term potential, then Boston should be at the top of your list.</p>


        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>#1 San Francisco/Bay Area, California</H3>
        <p>It should come as no surprise that San Francisco takes our top spot as the best city for real estate agents—after all, it was recently named the most expensive city in America by Forbes magazine. But despite its high cost of living, San Francisco remains one of the most desirable places to live in America thanks to its stunning scenery (the Golden Gate Bridge!), Mediterranean climate (which is ideal for outdoor activity), and wealth of job opportunities. If you're looking to make a killing in real estate then  San Francisco is definitely where you need to be.</p>

        <BlogSubtitle>Conclusion</BlogSubtitle>
        <p>These are just a few of many factors that set these cities apart from other metropolitan areas around North America but ultimately it’s up to each agent’s preference on what conditions they prefer such as: location, weather, type homes being sold etc. Trust your instinct, get out there and explore because knowledge Is power when It comes to making those big real estate decisions!</p>

        
        <BlogFooter />
        <Footer />
    </BlogPostSection>
    
  );
};

export default BestCities;
