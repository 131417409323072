import React from "react";
import styled from "styled-components";
import {
  Section,
  MidHeading,
  CustomerImageContainer,
  CustomerImageItem,
} from "./style.js";
import RemaxLogo from "images/remaxLogo.svg";
import NextGenLogo from "images/nextGenLogo.png";
import LovePropertyLogo from "images/lovePropertyLogo.svg";
import Testimonials from "./Testimonials"

const CustomerImages = (props) => {
  const dataTypes = [RemaxLogo, NextGenLogo, LovePropertyLogo];

  return (
    <Section>
      <MidHeading>Some of our happy customers</MidHeading>      
        <CustomerImageContainer>
        {dataTypes.map((data, index) => (
          <CustomerImageItem key={index}>                        
            <img src={data} alt="Customer logo" />
          </CustomerImageItem>
        ))}
        </CustomerImageContainer>     
        <Testimonials /> 
    </Section>
  );
};

export default CustomerImages;
