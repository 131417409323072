import React from "react";

import {
  DescCard,
  DescriptionSampleTitle,
  DescriptionSampleContent,
  DescSampleCardTitle,
  DescSampleSubHeading,
  DescSampleAddress
} from "./style.js";

const DescriptionCard = (props) => {
  return (
      <DescCard>
          <DescSampleCardTitle>
            <DescSampleSubHeading>DESCRIPTION FOR</DescSampleSubHeading>
            <DescriptionSampleTitle>{props.title}</DescriptionSampleTitle>
            <DescSampleAddress>{props.address}</DescSampleAddress>
          </DescSampleCardTitle>
          <DescriptionSampleContent>{props.description}</DescriptionSampleContent>
      </DescCard>
  );
};

export default DescriptionCard;
