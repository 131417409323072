import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { ErrorText } from "./GeneratorTwo.js";
import styled from "styled-components";
import "./app.css";

const SelectInput = styled.select`  
  border: 1.5px solid #E4E9EB;
  text-align: left;
  background: #F3F7F9;
  height: 51px;
  width:100%;
  display: inline-block;
  padding 6px;
  font-size: 14px;
  border-radius: 10px;
  line-height: 200%;
  font-weight: 500;
  min-width: 105px;
  display: flex;
  align-items: center;
  text-indent: 2px;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right center;
`;

const Container = styled.div`
  width: 100%;
  min-width: 320px;
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  margin-top: 1em;
`;

const Title = styled.p`
  font-size: 16px;
  color: #032231;
  font-weight: 700;
  margin: 0 0 1em 0;
  font-family: "Inter", sans-serif;
`;

const ButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: ${(props) => props.direction};
  width: 100%;

  & > * + * {
    margin-left: ${(props) => (props.direction == "row" ? "20px" : "")};
  }

  input {
    margin-right: 14px;
  }
`;

const MiniTitle = styled.p`
  :last-child {
    margin-bottom: 0px;
  }

  margin-bottom: ${(props) => (props.type == "propertyType" ? "1em" : "0px")};
`;

const SubDescription = styled.p`
  font-size: 12px;
  margin-top: -12px;
`

const LangSelectorContainer = (props) => {
  return (
    <Container>
      <Title>Language</Title>
      <ButtonContainer direction={props.direction}>


        <Field
          type="select"
          as={SelectInput}
          name={`${props.type}`}
          disabled={props.isDisabled}
        >
          <option value="afrikaans">Afrikaans</option>
          <option value="albanian">Albanian</option>
          <option value="arabic">Arabic</option>
          <option value="bengali">Bengali</option>
          <option value="brazilian portuguese">Brazilian Portuguese</option>
          <option value="bulgarian">Bulgarian</option>
          <option value="bulgarian">Bulgarian</option>
          <option value="Cantonese">Cantonese</option>
          <option value="catalan">Catalan</option>
          <option value="croatian">Croatian</option>
          <option value="czech">Czech</option>
          <option value="danish">Danish</option>
          <option value="dutch">Dutch</option>
          <option value="english us">English [US]</option>
          <option value="english uk">English [UK]</option>
          <option value="english pirate">English [Pirate]</option>
          <option value="estonian">Estonian</option>
          <option value="finnish">Finnish</option>
          <option value="french">French</option>
          <option value="french canadian">French Canadian</option>
          <option value="german">German</option>
          <option value="greek">Greek</option>
          <option value="hindi">Hindi</option>
          <option value="hong kong">Hong Kong</option>
          <option value="hungarian">Hungarian</option>
          <option value="indonesian">Indonesian</option>
          <option value="italian">Italian</option>
          <option value="japanese">Japanese</option>
          <option value="korean">Korean</option>
          <option value="latvian">Latvian</option>
          <option value="lithuanian">Lithuanian</option>
          <option value="malay">Malay</option>
          <option value="Mandarin">Mandarin</option>
          <option value="norwegian">Norwegian</option>
          <option value="pakistani">Pakistani</option>
          <option value="polish">Polish</option>
          <option value="portuguese">Portuguese</option>
          <option value="romanian">Romanian</option>
          <option value="russian">Russian</option>
          <option value="slovak">Slovak</option>
          <option value="slovenian">Slovenian</option>
          <option value="spanish">Spanish</option>
          <option value="swedish">Swedish</option>
          <option value="thai">Thai</option>
          <option value="turkish">Turkish</option>
          <option value="ukrainian">Ukrainian</option>
          <option value="vietnamese">Vietnamese</option>
        </Field>


      </ButtonContainer>
      <ErrorMessage component={ErrorText} name={`${props.type}`} />
    </Container>
  );
};

LangSelectorContainer.defaultProps = {
  direction: "row",
};

export default LangSelectorContainer;
