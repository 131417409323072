import React, {useEffect, useState} from "react";
import "./app.css";
import axios from "axios";

const Dashboard = () => {
    const [mydata, setData] = useState([]);
    useEffect(() => {
        axios.defaults.headers.common["X-CSRF-TOKEN"] = document.querySelector('meta[name="csrf-token"]').content;
        axios({
            method: "get",
            url: "/all_listings",
        })
        .then((response) => {
            setData(response.data.listings);
        })
        .catch((error) => {
            if (error.response && error.response.status === 401) {
                // Unauthorized, redirect to the login page
                window.location.href = "/users/sign_in";
            }
        });
    }, []);


    return (

        <div className="container">
            <div className="row text-center pricing-container">
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                    <p className="pricing-header">My Dashboard</p>
                    <div className="prev-gen-container">
                        <a className="create-new-listing" href="/generate_listing/0/''">
                            <div>
                                <h2 className="prev-gen-title">
                                    <img src={require('images/createIcon.png')} alt="Create Icon" className="create-icon" />
                                    Create a new listing
                                </h2>
                            </div>
                        </a>
                        {mydata.map((listing, i)=>(
                            <a className="prev-gen-content" href={"/generate_listing/" + listing.id +"/"+ encodeURIComponent(listing.unique_address)} key={listing.id}>
                                <div>
                                    <h2 className="prev-gen-title">{listing.address}</h2>
                                    <p className="prev-gen-subtitle">{new Date(listing.updated_at).toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric"
                                    })}</p>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
                <div className="col-lg-2"></div>
            </div>
        </div>


    )
}



export default Dashboard