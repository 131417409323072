import React from "react";

import Carousel from 'react-material-ui-carousel'
import { Paper } from '@material-ui/core'
import DescriptionCard from './DescriptionCard'

import {
    WideSection,
    WideSectionContainer,
    MidHeading
} from "./style.js";

var descriptions = [
    {
        title: "House",
        description: "Built in 2015, this unique contemporary style home is a must see! 3 bedrooms and 2 bathrooms with 1442 square feet of living space. The multi-level design features a open floor plan with a rec room on the basement level. The beautiful wooded backyard comes complete with a creek.\nThis property will have you feeling as if you were in the mountains! Your monthly rent already covers power, water, sewer and trash so all you need to do is move in! Washer and dryer are already in house. Pets are allowed as long as they are not destructive!\nContact us to book your viewing today!",
        address: "8820 E Raven Ct, Prescott Valley, AZ 86314, USA",
    },
    {
        title: "Condo",
        description: "Luxury condo rental for Business Bay! Spotless 1 bedroom and 2 bathrooms 926 sqft. Elegant, modern, high ceilings, wooden cabinets, marble floors, floor to ceiling windows, balconies...this is living the high life.\nClose to all amenities including Dubai Mall, Armani Hotel, The Palm, Burj Al Arab and many more. One of Dubai's most sought after neighborhoods. Free transportation available.\nContact us to book a viewing!",
        address: "Business Bay, Dubai, UAE",
    },
    {
        title: "Townhouse",
        description: "Contemplate life from the balcony off the second-floor living room of this 2-story townhome on Gateshead Drive. Two bedrooms and two bathrooms with 1,600 square feet of living space are waiting for you to move in.\nThe property comes with 2 parking spots, and a second-story foyer overlooking the dining area and kitchen below. 2010s sun-lit townhome offers plenty of cabinets, wood burning fireplacewith mantel, new carpeting on the upper level, newer washer/dryer, and sliding glass doors off the living room. One car garage to your left.\nContact us to book a viewing!",
        address: "19904 Gateshead CirGermantown, MD 20876, USA",
    },
    {
        title: "Social Media Copy",
        description: "Just listed 📈\n\nSpacious apartment with 2 bedrooms, 1 bathroom, and two off-street parking spots.\n🙌\nMinutes from the beach and Clouds FM. 😎\n👨‍🏫\nGreat for downsizing or as an investment property! ⛽️💰💵\nListed at $598,000",
    },
]


const WhatSection = (props) => {
  return (
    <WideSectionContainer>
        <WideSection>
            <MidHeading>Unique descriptions & social copy</MidHeading>
            <Carousel>
                {descriptions.map((data, index) => (
                    <Paper key={index}>
                        <DescriptionCard key={index} title={data["title"]} description={data["description"]} address={data["address"]}/>
                    </Paper>
                ))}
            </Carousel>
        </WideSection>
    </WideSectionContainer>
  );
};

export default WhatSection;
