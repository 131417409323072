import React from "react";
import styled from "styled-components";
import { BasicSection, Divider, Content } from "./style.js";
import Footer from "./Footer.js";

const TermsOfUse = () => {
  return (
    <BasicSection style={{ textAlign: "left" }}>
      <Content>
        <h2>Terms of Use</h2>
        <br />
        <h5>Overview</h5>
        <p>ListingAI offers these services to you, a user, under the condition that you accept all of our set terms, including these Terms of Service and all related policies.</p>
        <p>By using our website, using ListingAI or otherwise making use of our services, you agree to our terms and conditions as laid out in these Terms of Service. We strongly encourage you to read these Terms of Service thoroughly before using or continuing use of our services. If you do not agree to any of the terms in these Terms of Service or related policies, then you may not make use of our services.</p>
        <p>These Terms of Service may be changed in any way at any time by ListingAI without prior notification. The most recent version of our Terms of Service can be viewed at any time on this webpage. It is the user’s own responsibility to check this page for changes to our terms and change their agreement to said terms accordingly if required. We encourage checking this page regularly for changes.</p>

        <h5>Applicable law</h5>
        <p>ListingAI is located in Canada. Thus, ListingAI, are subject to laws and regulations in place in Canada. All legal activity related to us, our activities or any agreements between us and the user shall take place under the jurisdiction of Canada.</p>
        <p>In the case that any of the terms in these Terms of Services or our other policies are found to be unlawful, unenforceable or invalid, then the term in question shall instead be enforced to the maximum extent that the law or governing institute allows. When such unenforceability is determined, this will not affect the state of any other terms.</p>
        <p>If any of our products or services are considered illegal or otherwise unlawful in the user’s geographical region or under the jurisdiction the user is subject to, then the offering of said products or service will immediately be void.</p>

        <h5>Terms of use</h5>
        <p>By agreeing to these Terms of Service and making use of our services, you confirm that you are at least 18 years of age, or that you are a legal parent or guardian of the person who will make use of our services.</p>
        <p>You may not use our products or services for, in combination with, or by using any illegal products or services. You may not use our products or services for any illegal purposes. You may not use our products or services for political goals.</p>
        <p>Breaching any of the terms in these Terms of Service will immediately terminate the agreement between us and you. This also means you may no longer make use of our services in any way.</p>

        <h5>General conditions</h5>
        <p>We reserve the right to refuse service to anyone of whom we have substantial reason to presume they may misuse our services or violate our Terms of Service or related policies.</p>
        <p>You agree to not copy, duplicate or otherwise reproduce any part of our website, product or any other services. Exceptions include explicit written permission from us and legal rights like citations.</p>
        <p>You agree to not (re)distribute any part of our website, product or any other services outside of the terms laid out in our Terms of Service and related policies, outside of your legal right.</p>

        <h5>Services</h5>
        <p>We do our best to make any images, previews and other representations of our products on our website as accurate to the final product as possible. However, it’s possible that the final product that is delivered to you may still differ in small ways from the representations of said product on our platforms.</p>
        <p>In the case any part of our paid services is not to your liking, you have the right to a refund within the limitations of our Refund Policy.</p>
        <p>We reserve the right to change product specifications at any time without prior notice. We also reserve the right to stop the sale of any of our products and the offering of any of our services at any time without prior notice.</p>
        <p>We reserve the right to restrict the selling of our products and the offering of any of our services to people within any given geographic region or under any given jurisdiction. Should this restriction apply to you, we will inform you why we made this decision.</p>
        <p>We reserve the right to limit the amount of copies we can offer of any given product, both in total and to a specific user.</p>
        <p>We reserve the right to refuse any order, transaction or provision of services for one of the following reasons:</p>
        <p>(Substantial reason to believe that) the user is engaging in illegal activities, or may do so using our services When we are unable to provide you with your requested services, for example because of stock limitations (Substantial reason to believe that) the user is engaging in reselling or redistributing of our products or services</p>
        <p>When we refuse you service for any given reason, we shall inform you through the e-mail address you have provided us with while making use of our services.</p>

        <h5>Accuracy of information</h5>
        <p>On our platforms, we may provide information on the use of our own products or external products to reach a given goal. This information is provided as a source of general information. </p>
        <p>We do not guarantee this information is up-to-date or that it will provide any kind of outcome. We are not liable for any results from directly using any of the information on our platforms.</p>
        <p>We reserve the right to change any information on our website or any other communication at any moment without prior notice, without any obligation to do so.</p>

        <h5>Changes to our services</h5>
        <p>We reserve the right to change the price of any of our offerings without prior notice.</p>
        <p>We reserve the right to modify any of our services or discontinue any (part) of our services at any time without notice.</p>
        <p>We are not liable to you or any third parties for the consequences you or any third party experience from any changes we make to our services.</p>

        <h5>Accuracy and processing of your information</h5>
        <p>When making use of (parts of) our services, you may need to provide us with certain personal information. You agree to provide us with accurate, current and complete information. You agree that, should any information you have given change and thus become inaccurate, you will update this information within a timeframe.</p>
        <p>In order to provide you with our services, we need to process some of your personal information. The terms that apply to this processing of your information is laid out in our privacy policy.</p>

        <h5>Third parties</h5>
        <p>Some of our services may make use of services provided by third parties. We may also link to the websites of third parties in our communication. These parties may sometimes abide by different terms of service. We are not responsible for any of the terms these parties enforce or the consequences this enforcement has. When we make use of the services of third parties with unique terms separate from ours, we will explicitly inform you about this and won’t activate their services until you’ve given explicit permission. When you go to an external third party website from our own website, it is your own responsibility to familiarize yourself with their terms of service.</p>
        <p>We are in no way liable for any consequences that may arise from the use of services provided by third parties that aren’t explicitly affiliated with us.</p>

        <h5>Errors</h5>
        <p>It’s possible that any given information we provide in any form may contain errors or inaccuracies, either because vital information is missing or because the information given is inaccurate. This includes information about prices, promotions, availability, et cetera. In the event of such a mistake being made, we reserve the right to correct it without prior notice and without any obligation to do so. We also reserve the right to, after having corrected a mistake, update or cancel any orders and transactions made with the faulty information. In the event we need to do this, we shall inform you about this through the email address you will have provided us with.</p>

        <h5>Disclaimer of warranties</h5>
        <p>We cannot and thus do not guarantee that our services are provided with no interruptions, mistakes, errors, performance issues, delays or security issues. We try our best to provide you with the best possible service, but we can’t rule out that occasional problems with our services may arise.</p>
        <p>We do not warrant any results from the use of our products or services.</p>
        <p>Use of our service is at your own risk. Our products and services are provided without any warranties regarding their effect, functionality, or any other type of guarantee.</p>
        <p>ListingAI is not liable for any negative consequences caused by the use of our products by the user in any way, unless otherwise determined by the law. In such cases, ListingAI will be liable to the minimum extent the law requires.</p>

        <h5>Termination</h5>
        <p>These Terms of Service are effective from the moment you agree to them conform the guidelines laid out in these terms. The Terms of Service remain effective until either we or you terminate the agreement.</p>
        <p>You have the right to terminate the agreement at any time. If you wish to terminate the agreement, you may contact us via one of our customer support channels to inform us about this. Termination of the agreement also leads to direct loss of access to your account and all digital products you’ve purchased.</p>
        <p>We have the right to terminate the agreement without notice if there is substantial reason for us to believe that you have breached any of the terms within these Terms of Service.</p>
        <p>We have the right to terminate the agreement without notice if there is substantial reason for us to believe that you have breached any of the terms within these Terms of Service.</p>

        <h5>Entirety of the agreement</h5>
        <p>These Terms of Service and all other policies we uphold for the provision of our services transcend all other agreements between ListingAI and you, including communication between the two parties, any prior agreements and previous versions of the Terms of Service.</p>

        <br/>
      </Content>
      <Footer />
    </BasicSection>
  );
};

export default TermsOfUse;
