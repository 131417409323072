import React from "react";

import {
  WideSection,
  WideSectionContainer,
  FlexContainer,
  TextContainer,
  HowItWorksHeading,
  HowItWorksNumber,
  H3,
  MidHeading
} from "./style.js";


const HowItWorks = () => {
  return (
    <WideSectionContainer style={{paddingBottom: "60px"}}>
      <WideSection>
        <MidHeading>How it works</MidHeading>
        <FlexContainer style={{marginTop: "60px"}}>
          <TextContainer>
            <HowItWorksNumber>1</HowItWorksNumber>
            <HowItWorksHeading>Add property details</HowItWorksHeading>
            <H3>
              Add your listing’s basic information, unique attributes, and any extra information you believe is worth mentioning
            </H3>
          </TextContainer>
          <TextContainer>
            <HowItWorksNumber>2</HowItWorksNumber>
            <HowItWorksHeading>Generate listing description</HowItWorksHeading>
            <H3>
              Hit the generate button to create 3 unique descriptions based on the details of the property
            </H3>
          </TextContainer>
          <TextContainer>
            <HowItWorksNumber>3</HowItWorksNumber>
            <HowItWorksHeading>Tweak and copy</HowItWorksHeading>
            <H3>
              Edit your favorite description to your liking, then copy it to display in your listing or property website
            </H3>
          </TextContainer>
        </FlexContainer>
      </WideSection>
    </WideSectionContainer>
  );
};

export default HowItWorks;
