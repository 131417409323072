import  React, { useEffect } from "react";
import styled from "styled-components";
import {
  BlogPostSection,
  BlogTitle,
  BlogSubtitle,
  Breadcrumbs,
  BlogImage,
  H3
} from "../style.js";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import BlogFooter from "./BlogFooter"

import HeaderImage from "images/topmistakes.png";

const BiggestMistakes = () => {

  return (
    <BlogPostSection>
        <Breadcrumbs>          
            <li><Link to="/">Home</Link></li>
            <span>/</span>
            <li><Link to="/blog">Blog</Link></li>
            <span>/</span>
            <li>The Five Biggest Mistakes Real Estate Agents Make</li>          
        </Breadcrumbs>
        <BlogTitle>
            The Five Biggest Mistakes Real Estate Agents Make
        </BlogTitle>

        <BlogImage src={HeaderImage} style={{marginBottom: "1.5em"}} alt="Letters Forming Message Learn From Mistakes" />

        <p>Being a real estate agent is a tough job. You're constantly hustling to find new leads, close deals, and build relationships with clients. It's easy to get caught up in the day-to-day grind and forget about the bigger picture. But if you're not careful, you can make some serious mistakes that will jeopardize your entire business. In this article, we'll take a look at the five biggest mistakes real estate agents make and how you can avoid them.</p>

        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>1. Making a bad first impression</H3>
        <p>First impressions are critical in real estate; in fact, many buyers will form their opinion of a listing within the first few seconds of seeing it. Make sure that your listing photos are high-quality and show off your home in its best light; otherwise, buyers may scroll right past it without giving it a second thought. Once prospective buyers are at your property, pay attention to curb appeal and staging; these factors can make or break a sale.</p>
        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>2. Forgetting about past clients</H3>
        <p>Just because someone has already used your services doesn't mean they're never going to need you again. In fact, past clients are some of your best potential future clients. They already know, like, and trust you, so they're more likely to do business with you again or refer you to someone they know. Make sure you stay in touch with past clients and keep them updated on what's new with your business. This can be as simple as sending them a quarterly e-newsletter or dropping by their house every now and then to say hi.</p>
        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>3. Not understanding the market</H3>
        <p>It's important to know whether you're in a buyer's market or a seller's market, as this will impact pricing and the negotiation process. Generally speaking, in a buyer's market there are more homes for sale than there are buyers, so prices are lower and buyers have more negotiating power. In a seller's market, there are more buyers than there are homes for sale, so prices are higher and sellers have more negotiating power. Pay attention to trends in your local market so that you can adjust your strategy accordingly.</p>
        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>4. Not having a plan B </H3>
        <p>No matter how well you plan or how experienced you are, there will always be times when things don't go according to plan. That's why it's so important to have a plan B—or even a plan C —for when those inevitable hiccups occur. Whether it's having extra marketing materials on hand in case something gets damaged or delayed, being able to quickly change gears if a deal falls through, or knowing where to turn for help when you're feeling overwhelmed, make sure you have a backup plan for when things don't go as expected. </p>
        <H3 style={{fontWeight: "800", fontSize: "1.25em"}}>5. Not pricing your home correctly</H3>
        <p>Overpricing your home is one of the quickest ways to turn away potential buyers. If your home is priced too high, buyers will feel like they can get a better deal elsewhere and move on to other listings. However, if you price your home too low, you'll end up leaving money on the table. Work with a qualified appraiser to get an accurate estimate of your home's value before listing it for sale.</p>

        <BlogSubtitle>Conclusion</BlogSubtitle>
        <p>By avoiding these five common mistakes, you'll be on your way to success as a real estate agent. To recap, pay attention to market trends, price your listings correctly, prepare your homes for showings and focus on first impressions and you'll be well on your way to becoming a top-producing agent in no time!</p>

        
        <BlogFooter />
        <Footer />
    </BlogPostSection>
    
  );
};

export default BiggestMistakes;
