import React from "react";
import styled from "styled-components";
import {
  BlogHeaderSection,
  HeaderContainer,  
  HeaderText,
  SubHeading,
  Divider,
} from "../style.js";

const Header = () => {
  return (
    <BlogHeaderSection>
      <HeaderContainer>
        <HeaderText>
        ListingAI Real Estate Blog
        </HeaderText>
        <SubHeading>
        Subscribe below to get more real estate tips, tricks, and tools to improve your workflow, delivered directly to your inbox.
        </SubHeading>     

      <div id="mc_embed_signup">
        <form action="https://gmail.us1.list-manage.com/subscribe/post?u=7e5f8c9b0d19527e34fc85d9a&amp;id=1b07e503d6" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">            
        <div className="mc-field-group">            
            <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Enter your email..." />
        </div>        
            <input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
            </div>
        </form>
      </div>
      </HeaderContainer>
      <Divider />
    </BlogHeaderSection>
  );
};

export default Header;
