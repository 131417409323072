import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import styled from "styled-components";
import "./app.css";
import { ErrorText, Container } from "./GeneratorTwo.js";

const Title = styled.p`
  font-size: 16px;
  color: #032231;
  font-weight: 700;
  margin: 0 0 1em 0;
  font-family: "Inter", sans-serif;
`;

const InnerContainer = styled.div`
  flex-direction: column;
`;

const TextArea = styled.textarea`
  font-size: 14px;
  padding: 6px 8px;
  border: 1.5px solid #e4e9eb;
  background: #f3f7f9;
  width: 100%;
  border-radius: 10px;
  height: ${props => props.propertyType === "Vacant Land" ? '276px' : '434px'};
  font-family: "Inter", sans-serif;
  resize: vertical;
`;

const Counter = styled.span`
  position: absolute;
  bottom: 4px;
  right: 18px;
  font-size: 12px;
  opacity: 0.5;

  & span {
    margin: 0 1px 0 1px;
  }
`;

export const InputDiv = styled.div`
  margin-bottom: 0px !important;
  flex-direction: column;
  position: relative;
`;

const FreeFlowContainers = (props) => {
  const [count, setCount] = useState({
    propertyDetails: "0",
  });
  function handleCount(event) {
    const value = event.target.value;
    const length = value.length;
    const name = event.target.name;

    setCount({
      ...count,
      [name]: length,
    });
  }


  const placeholder = "quartz countertops, fireplace, balcony, quiet neighborhood, close to amenities, river views, etc.";

  return (
    <Container>
      <InnerContainer key="propertyDetails">
        <Title>Property details</Title>
        <InputDiv>
          <Field
            as={TextArea}
            propertyType = {props.propertyType}
            type="textarea"
            placeholder={placeholder}
            name="propertyDetails"
            disabled={props.isDisabled}
            onKeyUp={handleCount}
            maxLength="1000"
          />
          <Counter>
            {count["propertyDetails"]}
            <span>/</span>1000
          </Counter>
        </InputDiv>
        <ErrorMessage component={ErrorText} name="propertyDetails" />
      </InnerContainer>
    </Container>
  );
};

export default FreeFlowContainers;
