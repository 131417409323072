import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import "./app.css";
import StarSelector from "./StarSelector.js"
import FeedbackSubmit from "./FeedbackSubmit.js"
import ThankYou from "./ThankYou.js"

const Container = styled.div`
  min-width: 320px;
  width: 100%;
  min-height: 250px;
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  margin: 2em auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
    
const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & p {
    width: 400px;
    text-align: center;
  }

  @media screen and (max-width: 780px) {
    & p {
      width: 300px;
    }
  }
`;

const RatingPrompt = (props) => {  
  const [step, setStep] = useState(1)
  const [ratingIds, setRatingIds] = useState([]);

  function nextStep() {    
    setStep(step + 1);    
  }

  useEffect(() => {
    if (step == 3) {
      setTimeout(() => {        
        props.hidePrompt();
      }, 1800);
    }
  })

  function setStar(rating) {             
    const params = new URLSearchParams();
    
    params.append(
      "rating",
      JSON.stringify(rating)
    );

    let token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    props.descriptions.map((desc) => {
      axios({
        method: "post",
        url: `/api/v1/descriptions/${desc["id"]}/add_rating`,
        data: params,
      })
      .then((resp) => {        
        ratingIds.push(resp["data"]["id"])
        gtag("event", "description_rating_generated_success");
      })
      .catch(function (error) {
        console.log(error.response);
        gtag("event", "rating_failure");
      });
    })
  }

  function setFeedback(feedback) {                 

    const params = new URLSearchParams();

    params.append(
      "feedback",
      JSON.stringify(feedback)
    );

    let token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    props.descriptions.map((desc) => {
      params.append(
        "rating_ids",
        JSON.stringify(ratingIds)
      );
      
      axios({
        method: "post",
        url: `/api/v1/descriptions/${desc["id"]}/add_rating`,
        data: params,
      })
      .then((resp) => {        
        gtag("event", "description_generated_success");
      })
      .catch(function (error) {
        console.log(error.response.status);
        gtag("event", "rating_failure");
      });
    })
  }

  return (    
    <Container>      
        <InnerContainer>
          {step == "1" && <StarSelector setStar={setStar} nextStep={nextStep} />}
          {step == "2" && <FeedbackSubmit setFeedback={setFeedback} nextStep={nextStep} />}
          {step == "3" && <ThankYou />}
        </InnerContainer>      
    </Container>    
  );
};

export default RatingPrompt;