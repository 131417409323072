import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import styled from "styled-components";
import "./app.css";

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: -6px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 8px;
    border: 4px solid ${(props) => props.color};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.color} transparent transparent transparent;
        

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }

  & div:nth-child(2) {
    animation-delay: -0.3s;
  }

  & div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingSpinner = (props) => {
  return (
    <Spinner color={props.color}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Spinner>
  );
};

export default LoadingSpinner;
