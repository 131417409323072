import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import "./app.css";

const ThanksText = styled.p`
  margin-bottom: 0px;
  font-weight: 700;
`

const ThankYou = (props) => {
  return (
   <div>
        <ThanksText>          
          Thanks for sharing your feedback!
        </ThanksText>                  
   </div>
  );
};

export default ThankYou;