import React from "react";
import styled from "styled-components";
import {
  Section,
  OutlineButton,
  CtaButton,
  SubHeading,
  HeaderText,
  ButtonContainer,
  HeaderContainer,
} from "./style.js";
import { Link } from "react-router-dom";

function handleClick() {
  gtag("event", "lander_bottom_cta_click");
  window.location.href = "/users/sign_up";
}

const SignupBottom = () => {
  return (
    <Section style={{paddingLeft: "1em", paddingRight: "1em"}}>
      <HeaderContainer>
        <HeaderText>Get started with ListingAI</HeaderText>
        <SubHeading>
          ✔️  2 free descriptions ✔️  No credit card required ✔️  Cancel anytime
        </SubHeading>
        <ButtonContainer>
          <CtaButton onClick={handleClick}>Try now - <span style={{fontWeight: "500"}}>it’s free!</span></CtaButton>
        </ButtonContainer>
      </HeaderContainer>
    </Section>
  );
};

export default SignupBottom;
