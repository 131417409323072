import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import styled from "styled-components";
import "./app.css";
import BasicHouseAttribute from "./BasicHouseAttributes.js";
import ButtonSelectorContainer from "./ButtonSelectorContainer.js";
import LangSelectorContainer from "./LangSelectorContainer.js";
import OutputSelector from "./OutputSelector.js";
import DescriptionOutput from "./DescriptionOutput.js";
import Footer from "./landingPage/Footer.js";
import LoadingSpinner from "./LoadingSpinner.js";
import PropertyDetailsContainer from "./PropertyDetailsContainer.js";

import { AddressAutofill, config } from '@mapbox/search-js-react';
import { v4 as uuidv4 } from 'uuid';

const MainContainer = styled.div`
  display: block;
  padding-bottom: 2em;
  max-width: 48em;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 780px) {
    padding: 0 1em 0 1em;
  }
`;

const GenerateButtonContainer = styled.div`
  text-align: center;
`;

const LoadingText = styled.div`
  font-style: italic;
  margin-top: 10px;
  min-height: 24px;
  margin-top: 1.5em;
`;

export const ErrorText = styled.p`
  color: red;
  margin: 12px 0px 0px 0px;
  font-size: 12px;
`;

const GenerateButton = styled.button`
  background: #032231;
  color: #fff;
  border-radius: 20px;
  margin: 0 auto;
  width: 320px;
  min-height: 64px;
  justify-content: center;
  padding: 20px;
  display: flex;
  border: none;
  cursor: pointer;
  font-weight: 700;
  &:hover {
    opacity: 0.8;
  }
  outline: none;

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 634px) {
    width: 100%;
  }
`;

const AttributeContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  padding-bottom: 2em;

  & > * + * {
    margin: 0 0 0 2em;
  }

  @media screen and (max-width: 704px) {
    flex-direction: column;

    & > * + * {
      margin: 0 0 0 0;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: center;

  &:nth-child(1) {
    align-items: flex-end;
  }
  &:nth-child(2) {
    align-items: flex-start;
  }

  @media screen and (max-width: 850px) {
    &:nth-child(1) {
      align-items: center;
    }
    &:nth-child(2) {
      align-items: center;
    }
  }
`;

export const Container = styled.div`
  min-width: 320px;
  width: 100%;
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  margin-top: 1em;

  & div {
    display: flex;
  }
  & div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Title = styled.p`
  font-size: 16px;
  color: #032231;
  font-weight: 700;
  margin: 0 0 1em 0;
  font-family: "Inter", sans-serif;
`;

const InputField = styled.input`
  font-size: 14px;
  padding: 6px 8px;
  border: 1.5px solid #e4e9eb;
  background: #f3f7f9;
  width: 100%;
  height: 51px;
  border-radius: 10px;
`;

const InputDiv = styled.div`
  margin-bottom: 0px !important;
`;

const generalSchema = {
  characterCount: Yup.number()
    .positive()
    .required("Description Length Required")
    .min(100, "Generated description can't be shorter than 100 words")
    .max(500, "Generated description can't be longer than 500 words"),
  bedrooms: Yup.number().min(0).required("Number of Bedrooms Required"),
  propertyAddress: Yup.string().required("Address of Property Required"),
  bathrooms: Yup.number().positive().required("Number of Bathrooms Required"),
  areaSize: Yup.number().positive().required("Size of Property Required"),
  parking: Yup.number().min(0),
  transactionType: Yup.string().required("Transaction Type Required"),
  creativityMode: Yup.string().required("AI Writing Creativity Level Required"),
  propertyType: Yup.string().required("Property Type Required"),
  propertyDetails: Yup.string().max(
    1000,
    "Kitchen details must be shorter than 1000 characters"
  ),
}

const vacantLandSchema = {
  characterCount: Yup.number()
    .positive()
    .required("Description Length Required")
    .min(100, "Generated description can't be shorter than 100 words")
    .max(500, "Generated description can't be longer than 500 words"),
  propertyAddress: Yup.string().required("Address of Property Required"),
  areaSize: Yup.number().positive().required("Size of Property Required"),
  zoning: Yup.string().required("Zoning Information Required"),
  transactionType: Yup.string().required("Transaction Type Required"),
  creativityMode: Yup.string().required("AI Writing Creativity Level Required"),
  propertyType: Yup.string().required("Property Type Required"),
  propertyDetails: Yup.string().max(
    1000,
    "Kitchen details must be shorter than 1000 characters"
  ),
}

const GeneratorNew = () => {
  const [descriptions, setDescriptions] = useState();
  const [socials, setSocials] = useState();
  const [outputToggle, setOutputToggle] = useState("description");
  const [generationLimit, setGenerationLimit] = useState();
  const [activeSub, setActiveSub] = useState();
  const [unlimited, setUnlimited] = useState();
  const [showRating, setShowRating] = useState(true);
  const [mapboxToken, setMapboxToken] = useState('');
  const [propertyType, setPropertyType] = useState("House");

  function handlePrompt() {
    setShowRating(false);
  }

  useEffect(() => {
    getGenerationLimit();
  }, []);

  const schema = propertyType === "Vacant Land" ? vacantLandSchema : generalSchema
  const GenerationSchema = Yup.object().shape(schema);

  useEffect(() => {
    const accessToken = "pk.eyJ1IjoiY290dGFnZW1hcHMiLCJhIjoiY2x0ajZtM3FzMG5oMzJpcnJsY2swNmo5bCJ9.kIcKN5vmKR8uI3eH9RyVcg";
    setMapboxToken(accessToken);
    config.accessToken = accessToken;
    document.getElementById("propertyAddress").name = "propertyAddress";
  }, []);

  async function getGenerationLimit() {
    let token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      method: "get",
      url: "/api/v1/generation_limits",
    })
      .then((resp) => {
        setGenerationLimit(resp["data"].generations);
        setActiveSub(resp["data"].activeSub);
        setUnlimited(resp["data"].unlimited);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }

  function socialGenerated(results) {
    setSocials(results);
    setOutputToggle("social");
  }

  return (
    <Formik
      initialValues={{
        propertyAddress: "",
        unitNumber: "",
        language: "english us",
        characterCount: "250",
        bedrooms: "",
        bathrooms: "",
        areaMeasurement: "sqFeet",
        areaSize: "",
        parking: "",
        zoning: "",
        transactionType: "",
        creativityMode: "Neutral",
        propertyType: "",
        propertyDetails: "",
      }}
      validationSchema={GenerationSchema}
      onSubmit={async (data) => {
        //show the fields
        var descriptions = [{ id: 1, result: "" }]
        setDescriptions(descriptions);

        const channelName = uuidv4();

        const pusher = new Pusher('30da35e03bd157814eff', {
          cluster: 'us2'
        });
        const channel = pusher.subscribe(channelName);

        channel.bind('description-generation', function (data) {
          const content = data.message.choices[0].delta.content;
          if (content === undefined) {
            pusher.disconnect();
            setShowRating(true);
          } else {
            setDescriptions(prevDescriptions => {
              return [
                {
                  ...prevDescriptions[0],
                  ["result"]: prevDescriptions[0].result + content,
                }
              ]
            });
          }
        });

        setOutputToggle("description");

        let address = data["propertyAddress"];
        if (data["unitNumber"].trim() !== "") {
          address += " (unit: " + data["unitNumber"] + ")";
        }

        const params = new URLSearchParams();

        params.append(
          "transactionType",
          JSON.stringify(data["transactionType"])
        );
        params.append("propertyType", JSON.stringify(data["propertyType"]));
        params.append(
          "propertyAddress",
          JSON.stringify(address)
        );

        params.append("language", JSON.stringify(data["language"]));

        params.append("bedrooms", JSON.stringify(data["bedrooms"]));
        params.append("bathrooms", JSON.stringify(data["bathrooms"]));
        params.append("areaSize", JSON.stringify(data["areaSize"]));
        params.append("areaMeasurement", JSON.stringify(data["areaMeasurement"]));

        params.append("parking", JSON.stringify(data["parking"]));
        params.append("zoning", JSON.stringify(data["zoning"]));
        params.append("wordCount", JSON.stringify(data["characterCount"]));
        params.append("propertyDetails", JSON.stringify(data["propertyDetails"]));
        params.append("creativityMode", JSON.stringify(data["creativityMode"]));

        params.append("channel", JSON.stringify(channelName));


        let token = document.querySelector('meta[name="csrf-token"]').content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

        await axios({
          method: "post",
          url: "/api/v1/descriptions",
          data: params,
        })
          .then((resp) => {
            console.log("params", params);
            console.log("response", resp["data"]);
            getGenerationLimit();
            setDescriptions(prevDescriptions => {
              return [
                {
                  ...prevDescriptions[0],
                  ["id"]: resp["data"][0]["description"]["id"],
                }
              ]
            });
            gtag("event", "description_generated_success");
          })
          .catch(function (error) {
            console.log(error.response.status)
            if (error.response.status === 402) {
              console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers)
              gtag("event", "description_generated_failure_plan_expired")
              window.location.href = activeSub ? "pricing?outofsubgens" : "pricing?outofgens"
            }
            gtag("event", "description_generated_failure")
          })
      }}
    >
      {({ isSubmitting, setTouched }) => (
        <Form>
          <MainContainer>
            <AttributeContainer>
              <Column>
                <Container>
                  <Title>Property Address</Title>
                  <AddressAutofill accessToken={mapboxToken}>
                    <InputDiv>
                      <Field
                        autoComplete="full-address"
                        as={InputField}
                        placeholder="Property address"
                        name="propertyAddress"
                        id="propertyAddress"
                        type="string"
                        disabled={isSubmitting}
                      />
                    </InputDiv>
                  </AddressAutofill>
                  <ErrorMessage component={ErrorText} name="propertyAddress" />

                  <InputDiv>
                    <Field
                      as={InputField}
                      type="string"
                      placeholder="Unit # (optional)"
                      name="unitNumber"
                      style={{ marginTop: 20 }}
                      disabled={isSubmitting}
                    />
                  </InputDiv>
                </Container>
                <ButtonSelectorContainer
                  direction="column"
                  title="Property type"
                  type="propertyType"
                  isDisabled={isSubmitting}
                  value={propertyType}
                  onChange={(e) => { setPropertyType(e.target.value); setTouched({}, false);}}
                />

                <Container>
                  <Title>Description length (words)</Title>
                  <InputDiv>
                    <Field
                      as={InputField}
                      type="number"
                      min="0"
                      placeholder="Number of words (minimum 100 words)"
                      name="characterCount"
                      disabled={isSubmitting}
                    />
                  </InputDiv>
                  <ErrorMessage component={ErrorText} name="characterCount" />
                </Container>

                <ButtonSelectorContainer
                  title="AI Writing Creativity"
                  type="creativityMode"
                  description="Adjust how creative the AI should write. Neutral is recommended for increased accuracy."
                  hasDescription
                  isDisabled={isSubmitting}
                />
                <LangSelectorContainer
                  title="Language"
                  type="language"
                  isDisabled={isSubmitting}
                />
              </Column>
              <Column>
                <Container>
                  <Title>Basic attributes</Title>
                  <BasicHouseAttribute isDisabled={isSubmitting} propertyType={propertyType} />
                </Container>
                <ButtonSelectorContainer
                  title="Transaction type"
                  type="transactionType"
                  isDisabled={isSubmitting}
                />
                <PropertyDetailsContainer isDisabled={isSubmitting} />
              </Column>
            </AttributeContainer>
            <GenerateButtonContainer>
              <GenerateButton id="generateButton" type="submit">
                {!isSubmitting && "Generate description"}
                {isSubmitting && <LoadingSpinner color="#fff" />}
              </GenerateButton>
              <LoadingText>
                {isSubmitting && "This could take a minute"}

                {!isSubmitting && !unlimited ? (
                  <p>
                    You have {generationLimit} generation(s) remaining.{" "}
                    {!activeSub &&
                      <a href="/pricing">See pricing plans</a>
                    }
                    {(activeSub && generationLimit === 0) &&
                      <a href="/pricing">Upgrade your plan</a>
                    }
                  </p>
                ) : (
                  ""
                )}
              </LoadingText>
            </GenerateButtonContainer>
            <OutputSelector
              descriptions={descriptions}
              selection={outputToggle}
              onChangeSelection={setOutputToggle}
            />
            {outputToggle === "description" && (
              <DescriptionOutput
                showRatingPrompt={showRating}
                handlePrompt={handlePrompt}
                descriptions={descriptions}
                type="description"
                socialGenerated={socialGenerated}
                blur="true"
                defaultValue=""
                activeSub={activeSub}
              />
            )}
            {outputToggle === "social" && (
              <DescriptionOutput
                descriptions={socials}
                type="social"
                regenerate="false"
                blur="true"
                defaultValue=""
                activeSub={activeSub}
              />
            )}
            <Footer />
          </MainContainer>
        </Form>
      )}
    </Formik >
  );
};

export default GeneratorNew;
