import React from "react";
import styled from "styled-components";
import { BasicSection, Content } from "./style.js";
import Footer from "./Footer.js";

const Faq = () => {
  return (
    <BasicSection style={{ textAlign: "left" }}>
      <Content>
        <h2>Frequently Asked Questions</h2>
        <br />
        <p>
          <strong>Why are real estate listing descriptions important?</strong>
        </p>
        <p>
          Other than property photos, a well written property description is the
          only other way for a buyer to truly connect with the home you're
          selling.
        </p>
        <p>
          Data shows that{" "}
          <a href="https://www.nar.realtor/research-and-statistics/research-reports/highlights-from-the-profile-of-home-buyers-and-sellers">
            43% of buyers
          </a>{" "}
          start their home search online, and{" "}
          <a href="https://www.nar.realtor/research-and-statistics/research-reports/highlights-from-the-profile-of-home-buyers-and-sellers">
            86% of those buyers
          </a>{" "}
          searching online say that the detailed information found in the
          description is “very useful.” That’s not to mention that nearly{" "}
          <a href="https://www.redfin.com/blog/2014/03/grammar-matters.html">
            45% of buyers will be less inclined to tour a home
          </a>{" "}
          if they read a poorly written property description.
        </p>
        <br />
        <p>
          <strong>How does it work?</strong>
        </p>
        <p>
          Our app uses a new form of artificial intelligence called GPT-3, which
          based on the input you provide learns how to generate the best real
          estate listing descriptions in seconds.
        </p>
        <br />
        <p>
          <strong>Are all the descriptions unique?</strong>
        </p>
        <p>
          Yes, every description we output will be unique and custom based on
          the information you provide.
        </p>
        <br />
        <p>
          <strong>How expensive is your service?</strong>
        </p>
        <p>
          Pricing details can be found on our pricing page{" "}
          <a href="/pricing">here.</a>
        </p>
        <br />
        <p>
          <strong>What is your refund policy?</strong>
        </p>
        <p>
          We offer a 30-day money-back guarantee for all ListingAI purchases.
          Meaning if you don't like ListingAI for any reason within 30-days of
          the initial purchase, we'll refund your money, no questions asked. To
          request a refund please contact us directly at{" "}
          <a href="mailto:hello@listing-ai.herokuapp.com"> hello@listing-ai.herokuapp.com</a>
        </p>
        <br />
        <p>
          <strong>How can I contact you?</strong>
        </p>
        <p>
          We'd love to hear from you! You can email us directly at
          <a href="mailto:hello@listing-ai.herokuapp.com"> hello@listing-ai.herokuapp.com</a>
        </p>
        <br />
      </Content>
      <Footer />
    </BasicSection>
  );
};

export default Faq;
